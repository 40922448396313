import {
  AuthResponse,
  GetMyInfoResponse,
  GetMyBadgesResponse,
  AddEmployeeBadgeResponse,
  GetMyInterestsResponse,
  AddEmployeeInterestResponse,
  RemoveEmployeeInterestResponse,
} from '../../contract/zallengine_zallweb';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { ZallEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';
import { UserInfo } from './index';
import { Badge } from '../index';
import { Interest } from '../wall';

export const validateAuthResp = (resp: AuthResponse): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.data || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateGetMyInfoResp = (
  resp: GetMyInfoResponse,
): [UserInfo, UserInfo | undefined, ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.data || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as UserInfo, undefined, errResp];
  }

  // user info
  const userInfo = {
    employee: resp.data,
    isImpersonationAllowed: resp.permissions?.isImpersonationAllowed || false,
    isBadgeAdmin: resp.permissions?.isBadgeAdmin || false,
  };

  // impersonator info, if any
  const impersonatorInfo =
    resp.impersonator && resp.impersonator.data
      ? {
          employee: resp.impersonator.data,
          isImpersonationAllowed: true,
          isBadgeAdmin: resp.impersonator.permissions?.isBadgeAdmin || false,
        }
      : undefined;

  return [userInfo, impersonatorInfo, errResp];
};

export const validateGetMyBadgesResp = (
  resp: GetMyBadgesResponse,
): [Badge[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.badges || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.badges, errResp];
};

export const validateAddEmployeeBadgeResp = (
  resp: AddEmployeeBadgeResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return errResp;
  }

  return errResp;
};

export const validateGetMyInterestsResp = (
  resp: GetMyInterestsResponse,
): [Interest[], ZallEngineErrorResponse] => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.interests || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[], errResp];
  }

  return [resp.interests, errResp];
};

export const validateAddEmployeeInterestResp = (
  resp: AddEmployeeInterestResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return errResp;
  }

  return errResp;
};

export const validateRemoveEmployeeInterestResp = (
  resp: RemoveEmployeeInterestResponse,
): ZallEngineErrorResponse => {
  const errResp: ZallEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return errResp;
  }

  return errResp;
};
