/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  StatusType,
  statusTypeFromJSON,
  statusTypeToJSON,
} from './gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { Int64Value, StringValue } from './google/protobuf/wrappers';
import { Badge, BadgeApplication, PendingBadge } from './zallengine_badge';
import { Employee, EmployeeFound } from './zallengine_employee';
import { Insights } from './zallengine_insights';
import { Interest } from './zallengine_interest';
import { Karma } from './zallengine_karma';
import { NamePronunciation } from './zallengine_name_pronunciation';
import { WallConfig } from './zallengine_wall_config';
import { WallDetail } from './zallengine_wall_detail';

export const protobufPackage = 'zallengine_contract';

export interface AuthResponse {
  status: StatusType;
  message: string;
  data: Employee | undefined;
  impersonator: Employee | undefined;
}

/** GetMyInfo */
export interface GetMyInfoRequest {}

export interface GetMyInfoResponse {
  status: StatusType;
  message: string;
  data: Employee | undefined;
  /** @deprecated */
  isSuperuser: boolean;
  /** @deprecated */
  isImpersonationAllowed: boolean;
  permissions: GetMyInfoResponse_Permissions | undefined;
  impersonator: GetMyInfoResponse_Impersonator | undefined;
}

export interface GetMyInfoResponse_Permissions {
  isImpersonationAllowed: boolean;
  isBadgeAdmin: boolean;
}

export interface GetMyInfoResponse_Impersonator {
  data: Employee | undefined;
  /** @deprecated */
  isSuperuser: boolean;
  permissions: GetMyInfoResponse_Permissions | undefined;
}

/** GetMyBadges */
export interface GetMyBadgesRequest {}

export interface GetMyBadgesResponse {
  status: StatusType;
  message: string;
  badges: Badge[];
}

/** GetMyInterests */
export interface GetMyInterestsRequest {}

export interface GetMyInterestsResponse {
  status: StatusType;
  message: string;
  interests: Interest[];
}

/** GetMyImpersonatees */
export interface GetMyImpersonateesRequest {}

export interface GetMyImpersonateesResponse {
  status: StatusType;
  message: string;
  isAllowedAll: boolean;
  impersonatees: EmployeeFound[];
}

/** GetWallConfig */
export interface GetWallConfigRequest {}

export interface GetWallConfigResponse {
  status: StatusType;
  message: string;
  data: WallConfig | undefined;
}

/** GetWallDetail */
export interface GetWallDetailRequest {
  username: string;
}

export interface GetWallDetailResponse {
  status: StatusType;
  message: string;
  data: WallDetail | undefined;
}

/** UpdateEmployeePronoun */
export interface UpdateEmployeePronounRequest {
  pronounId: string;
}

export interface UpdateEmployeePronounResponse {
  status: StatusType;
  message: string;
}

/** UpdateEmployeeFavoriteCoreValue */
export interface UpdateEmployeeFavoriteCoreValueRequest {
  coreValueId: string;
}

export interface UpdateEmployeeFavoriteCoreValueResponse {
  status: StatusType;
  message: string;
}

/** UpdateEmployeeFunFact */
export interface UpdateEmployeeFunFactRequest {
  funFactId: string;
  input: string;
}

export interface UpdateEmployeeFunFactResponse {
  status: StatusType;
  message: string;
}

/** UpdateEmployeePrimaryContactNumber */
export interface UpdateEmployeePrimaryContactNumberRequest {
  primaryContactNumber: string;
}

export interface UpdateEmployeePrimaryContactNumberResponse {
  status: StatusType;
  message: string;
}

/** UpdateEmployeeNamePronunciation */
export interface UpdateEmployeeNamePronunciationRequest {
  audio: Uint8Array;
  description: string | undefined;
  deleteAudio: boolean;
}

export interface UpdateEmployeeNamePronunciationResponse {
  status: StatusType;
  message: string;
  namePronunciation: NamePronunciation | undefined;
}

/** UpdateEmployeeLinkedInProfileUrl */
export interface UpdateEmployeeLinkedInProfileUrlRequest {
  linkedinProfileUrl: string;
}

export interface UpdateEmployeeLinkedInProfileUrlResponse {
  status: StatusType;
  message: string;
}

/** GetEmployeeKarmas */
export interface GetEmployeeKarmasRequest {
  employeeId: string;
  karmaType: GetEmployeeKarmasRequest_KarmaType;
  /** Pagination */
  start: string;
  size: string;
}

export enum GetEmployeeKarmasRequest_KarmaType {
  KARMA_TYPE_UNSPECIFIED = 0,
  KARMA_TYPE_SEND = 1,
  KARMA_TYPE_RECEIVE = 2,
  UNRECOGNIZED = -1,
}

export function getEmployeeKarmasRequest_KarmaTypeFromJSON(
  object: any,
): GetEmployeeKarmasRequest_KarmaType {
  switch (object) {
    case 0:
    case 'KARMA_TYPE_UNSPECIFIED':
      return GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_UNSPECIFIED;
    case 1:
    case 'KARMA_TYPE_SEND':
      return GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_SEND;
    case 2:
    case 'KARMA_TYPE_RECEIVE':
      return GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_RECEIVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return GetEmployeeKarmasRequest_KarmaType.UNRECOGNIZED;
  }
}

export function getEmployeeKarmasRequest_KarmaTypeToJSON(
  object: GetEmployeeKarmasRequest_KarmaType,
): string {
  switch (object) {
    case GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_UNSPECIFIED:
      return 'KARMA_TYPE_UNSPECIFIED';
    case GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_SEND:
      return 'KARMA_TYPE_SEND';
    case GetEmployeeKarmasRequest_KarmaType.KARMA_TYPE_RECEIVE:
      return 'KARMA_TYPE_RECEIVE';
    case GetEmployeeKarmasRequest_KarmaType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface GetEmployeeKarmasResponse {
  status: StatusType;
  message: string;
  karmas: Karma[];
}

/** DeleteEmployeeKarma */
export interface DeleteEmployeeKarmaRequest {
  karmaId: string;
}

export interface DeleteEmployeeKarmaResponse {
  status: StatusType;
  message: string;
}

/** GiveKarma */
export interface GiveKarmaRequest {
  receiverId: string;
  message: string;
  coreValueId: string | undefined;
  leadershipBlueprintId: string | undefined;
}

export interface GiveKarmaResponse {
  status: StatusType;
  message: string;
  karmaId: string;
}

/** GetInsightsTwins */
export interface GetInsightsTwinsRequest {
  username: string;
}

export interface GetInsightsTwinsResponse {
  status: StatusType;
  message: string;
  employee: Employee | undefined;
  insightTwins: Employee[];
}

/** GetEmployeesWithInsightsColorRequest */
export interface GetEmployeesWithInsightsColorRequest {
  color: string;
}

export interface GetEmployeesWithInsightsColorResponse {
  status: StatusType;
  message: string;
  employees: Employee[];
}

/** UpdateEmployeeInsights */
export interface UpdateEmployeeInsightsRequest {
  insights: Insights | undefined;
}

export interface UpdateEmployeeInsightsResponse {
  status: StatusType;
  message: string;
}

/** GetInterestWallDetail */
export interface GetInterestWallDetailRequest {
  interestId: string;
}

export interface GetInterestWallDetailResponse {
  status: StatusType;
  message: string;
  interest: Interest | undefined;
  assignees: Employee[];
}

/** GetAllInterests */
export interface GetAllInterestsRequest {}

export interface GetAllInterestsResponse {
  status: StatusType;
  message: string;
  interests: Interest[];
}

/** CreateInterest */
export interface CreateInterestRequest {
  name: string;
  description: string;
  iconImage: Uint8Array;
}

export interface CreateInterestResponse {
  status: StatusType;
  message: string;
  interestId: string;
}

/** AddEmployeeInterest */
export interface AddEmployeeInterestRequest {
  interestId: string;
}

export interface AddEmployeeInterestResponse {
  status: StatusType;
  message: string;
}

/** RemoveEmployeeInterest */
export interface RemoveEmployeeInterestRequest {
  interestId: string;
}

export interface RemoveEmployeeInterestResponse {
  status: StatusType;
  message: string;
}

/** GetBadgeWallDetail */
export interface GetBadgeWallDetailRequest {
  badgeId: string;
}

export interface GetBadgeWallDetailResponse {
  status: StatusType;
  message: string;
  badge: Badge | undefined;
  assignees: Employee[];
  owner: Employee | undefined;
}

/** GetAllBadges */
export interface GetAllBadgesRequest {}

export interface GetAllBadgesResponse {
  status: StatusType;
  message: string;
  badges: Badge[];
}

/** SubmitNewBadge */
export interface SubmitNewBadgeRequest {
  application: BadgeApplication | undefined;
}

export interface SubmitNewBadgeResponse {
  status: StatusType;
  message: string;
}

/** UpdateBadgeDetail */
export interface UpdateBadgeDetailRequest {
  badgeId: string;
  name: string;
  description: string;
  iconImage: Uint8Array;
}

export interface UpdateBadgeDetailResponse {
  status: StatusType;
  message: string;
  badge: Badge | undefined;
}

/** GetPendingBadges */
export interface GetPendingBadgesRequest {}

export interface GetPendingBadgesResponse {
  status: StatusType;
  message: string;
  badges: PendingBadge[];
}

/** SubmitBadgeDecision */
export interface SubmitBadgeDecisionRequest {
  badgeId: string;
  decision: SubmitBadgeDecisionRequest_BadgeDecisionType;
  reason: string;
}

export enum SubmitBadgeDecisionRequest_BadgeDecisionType {
  BADGE_DECISION_TYPE_REJECT = 0,
  BADGE_DECISION_TYPE_APPROVE = 1,
  UNRECOGNIZED = -1,
}

export function submitBadgeDecisionRequest_BadgeDecisionTypeFromJSON(
  object: any,
): SubmitBadgeDecisionRequest_BadgeDecisionType {
  switch (object) {
    case 0:
    case 'BADGE_DECISION_TYPE_REJECT':
      return SubmitBadgeDecisionRequest_BadgeDecisionType.BADGE_DECISION_TYPE_REJECT;
    case 1:
    case 'BADGE_DECISION_TYPE_APPROVE':
      return SubmitBadgeDecisionRequest_BadgeDecisionType.BADGE_DECISION_TYPE_APPROVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SubmitBadgeDecisionRequest_BadgeDecisionType.UNRECOGNIZED;
  }
}

export function submitBadgeDecisionRequest_BadgeDecisionTypeToJSON(
  object: SubmitBadgeDecisionRequest_BadgeDecisionType,
): string {
  switch (object) {
    case SubmitBadgeDecisionRequest_BadgeDecisionType.BADGE_DECISION_TYPE_REJECT:
      return 'BADGE_DECISION_TYPE_REJECT';
    case SubmitBadgeDecisionRequest_BadgeDecisionType.BADGE_DECISION_TYPE_APPROVE:
      return 'BADGE_DECISION_TYPE_APPROVE';
    case SubmitBadgeDecisionRequest_BadgeDecisionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface SubmitBadgeDecisionResponse {
  status: StatusType;
  message: string;
}

/** AddBadgeAssignee */
export interface AddBadgeAssigneeRequest {
  badgeId: string;
  employeeId: string;
}

export interface AddBadgeAssigneeResponse {
  status: StatusType;
  message: string;
}

/** RemoveBadgeAssignee */
export interface RemoveBadgeAssigneeRequest {
  badgeId: string;
  employeeId: string;
}

export interface RemoveBadgeAssigneeResponse {
  status: StatusType;
  message: string;
}

/** AddEmployeeBadge */
export interface AddEmployeeBadgeRequest {
  badgeId: string;
}

export interface AddEmployeeBadgeResponse {
  status: StatusType;
  message: string;
}

/** GetDirectReports */
export interface GetDirectReportsRequest {
  employeeId: string;
}

export interface GetDirectReportsResponse {
  status: StatusType;
  message: string;
  reports: Employee[];
}

/** SearchEmployees */
export interface SearchEmployeesRequest {
  keyword: string;
}

export interface SearchEmployeesResponse {
  status: StatusType;
  message: string;
  employees: EmployeeFound[];
}

function createBaseAuthResponse(): AuthResponse {
  return { status: 0, message: '', data: undefined, impersonator: undefined };
}

export const AuthResponse = {
  encode(message: AuthResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.data !== undefined) {
      Employee.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    if (message.impersonator !== undefined) {
      Employee.encode(message.impersonator, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = Employee.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.impersonator = Employee.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      data: isSet(object.data) ? Employee.fromJSON(object.data) : undefined,
      impersonator: isSet(object.impersonator) ? Employee.fromJSON(object.impersonator) : undefined,
    };
  },

  toJSON(message: AuthResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.data !== undefined) {
      obj.data = Employee.toJSON(message.data);
    }
    if (message.impersonator !== undefined) {
      obj.impersonator = Employee.toJSON(message.impersonator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthResponse>, I>>(base?: I): AuthResponse {
    return AuthResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthResponse>, I>>(object: I): AuthResponse {
    const message = createBaseAuthResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.data =
      object.data !== undefined && object.data !== null
        ? Employee.fromPartial(object.data)
        : undefined;
    message.impersonator =
      object.impersonator !== undefined && object.impersonator !== null
        ? Employee.fromPartial(object.impersonator)
        : undefined;
    return message;
  },
};

function createBaseGetMyInfoRequest(): GetMyInfoRequest {
  return {};
}

export const GetMyInfoRequest = {
  encode(_: GetMyInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetMyInfoRequest {
    return {};
  },

  toJSON(_: GetMyInfoRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInfoRequest>, I>>(base?: I): GetMyInfoRequest {
    return GetMyInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInfoRequest>, I>>(_: I): GetMyInfoRequest {
    const message = createBaseGetMyInfoRequest();
    return message;
  },
};

function createBaseGetMyInfoResponse(): GetMyInfoResponse {
  return {
    status: 0,
    message: '',
    data: undefined,
    isSuperuser: false,
    isImpersonationAllowed: false,
    permissions: undefined,
    impersonator: undefined,
  };
}

export const GetMyInfoResponse = {
  encode(message: GetMyInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.data !== undefined) {
      Employee.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    if (message.isSuperuser === true) {
      writer.uint32(32).bool(message.isSuperuser);
    }
    if (message.isImpersonationAllowed === true) {
      writer.uint32(40).bool(message.isImpersonationAllowed);
    }
    if (message.permissions !== undefined) {
      GetMyInfoResponse_Permissions.encode(message.permissions, writer.uint32(50).fork()).ldelim();
    }
    if (message.impersonator !== undefined) {
      GetMyInfoResponse_Impersonator.encode(
        message.impersonator,
        writer.uint32(242).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = Employee.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isSuperuser = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isImpersonationAllowed = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.permissions = GetMyInfoResponse_Permissions.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.impersonator = GetMyInfoResponse_Impersonator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyInfoResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      data: isSet(object.data) ? Employee.fromJSON(object.data) : undefined,
      isSuperuser: isSet(object.isSuperuser) ? globalThis.Boolean(object.isSuperuser) : false,
      isImpersonationAllowed: isSet(object.isImpersonationAllowed)
        ? globalThis.Boolean(object.isImpersonationAllowed)
        : false,
      permissions: isSet(object.permissions)
        ? GetMyInfoResponse_Permissions.fromJSON(object.permissions)
        : undefined,
      impersonator: isSet(object.impersonator)
        ? GetMyInfoResponse_Impersonator.fromJSON(object.impersonator)
        : undefined,
    };
  },

  toJSON(message: GetMyInfoResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.data !== undefined) {
      obj.data = Employee.toJSON(message.data);
    }
    if (message.isSuperuser === true) {
      obj.isSuperuser = message.isSuperuser;
    }
    if (message.isImpersonationAllowed === true) {
      obj.isImpersonationAllowed = message.isImpersonationAllowed;
    }
    if (message.permissions !== undefined) {
      obj.permissions = GetMyInfoResponse_Permissions.toJSON(message.permissions);
    }
    if (message.impersonator !== undefined) {
      obj.impersonator = GetMyInfoResponse_Impersonator.toJSON(message.impersonator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInfoResponse>, I>>(base?: I): GetMyInfoResponse {
    return GetMyInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInfoResponse>, I>>(object: I): GetMyInfoResponse {
    const message = createBaseGetMyInfoResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.data =
      object.data !== undefined && object.data !== null
        ? Employee.fromPartial(object.data)
        : undefined;
    message.isSuperuser = object.isSuperuser ?? false;
    message.isImpersonationAllowed = object.isImpersonationAllowed ?? false;
    message.permissions =
      object.permissions !== undefined && object.permissions !== null
        ? GetMyInfoResponse_Permissions.fromPartial(object.permissions)
        : undefined;
    message.impersonator =
      object.impersonator !== undefined && object.impersonator !== null
        ? GetMyInfoResponse_Impersonator.fromPartial(object.impersonator)
        : undefined;
    return message;
  },
};

function createBaseGetMyInfoResponse_Permissions(): GetMyInfoResponse_Permissions {
  return { isImpersonationAllowed: false, isBadgeAdmin: false };
}

export const GetMyInfoResponse_Permissions = {
  encode(
    message: GetMyInfoResponse_Permissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.isImpersonationAllowed === true) {
      writer.uint32(8).bool(message.isImpersonationAllowed);
    }
    if (message.isBadgeAdmin === true) {
      writer.uint32(16).bool(message.isBadgeAdmin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInfoResponse_Permissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInfoResponse_Permissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isImpersonationAllowed = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isBadgeAdmin = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyInfoResponse_Permissions {
    return {
      isImpersonationAllowed: isSet(object.isImpersonationAllowed)
        ? globalThis.Boolean(object.isImpersonationAllowed)
        : false,
      isBadgeAdmin: isSet(object.isBadgeAdmin) ? globalThis.Boolean(object.isBadgeAdmin) : false,
    };
  },

  toJSON(message: GetMyInfoResponse_Permissions): unknown {
    const obj: any = {};
    if (message.isImpersonationAllowed === true) {
      obj.isImpersonationAllowed = message.isImpersonationAllowed;
    }
    if (message.isBadgeAdmin === true) {
      obj.isBadgeAdmin = message.isBadgeAdmin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInfoResponse_Permissions>, I>>(
    base?: I,
  ): GetMyInfoResponse_Permissions {
    return GetMyInfoResponse_Permissions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInfoResponse_Permissions>, I>>(
    object: I,
  ): GetMyInfoResponse_Permissions {
    const message = createBaseGetMyInfoResponse_Permissions();
    message.isImpersonationAllowed = object.isImpersonationAllowed ?? false;
    message.isBadgeAdmin = object.isBadgeAdmin ?? false;
    return message;
  },
};

function createBaseGetMyInfoResponse_Impersonator(): GetMyInfoResponse_Impersonator {
  return { data: undefined, isSuperuser: false, permissions: undefined };
}

export const GetMyInfoResponse_Impersonator = {
  encode(
    message: GetMyInfoResponse_Impersonator,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.data !== undefined) {
      Employee.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    if (message.isSuperuser === true) {
      writer.uint32(16).bool(message.isSuperuser);
    }
    if (message.permissions !== undefined) {
      GetMyInfoResponse_Permissions.encode(message.permissions, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInfoResponse_Impersonator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInfoResponse_Impersonator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = Employee.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSuperuser = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.permissions = GetMyInfoResponse_Permissions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyInfoResponse_Impersonator {
    return {
      data: isSet(object.data) ? Employee.fromJSON(object.data) : undefined,
      isSuperuser: isSet(object.isSuperuser) ? globalThis.Boolean(object.isSuperuser) : false,
      permissions: isSet(object.permissions)
        ? GetMyInfoResponse_Permissions.fromJSON(object.permissions)
        : undefined,
    };
  },

  toJSON(message: GetMyInfoResponse_Impersonator): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = Employee.toJSON(message.data);
    }
    if (message.isSuperuser === true) {
      obj.isSuperuser = message.isSuperuser;
    }
    if (message.permissions !== undefined) {
      obj.permissions = GetMyInfoResponse_Permissions.toJSON(message.permissions);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInfoResponse_Impersonator>, I>>(
    base?: I,
  ): GetMyInfoResponse_Impersonator {
    return GetMyInfoResponse_Impersonator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInfoResponse_Impersonator>, I>>(
    object: I,
  ): GetMyInfoResponse_Impersonator {
    const message = createBaseGetMyInfoResponse_Impersonator();
    message.data =
      object.data !== undefined && object.data !== null
        ? Employee.fromPartial(object.data)
        : undefined;
    message.isSuperuser = object.isSuperuser ?? false;
    message.permissions =
      object.permissions !== undefined && object.permissions !== null
        ? GetMyInfoResponse_Permissions.fromPartial(object.permissions)
        : undefined;
    return message;
  },
};

function createBaseGetMyBadgesRequest(): GetMyBadgesRequest {
  return {};
}

export const GetMyBadgesRequest = {
  encode(_: GetMyBadgesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyBadgesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyBadgesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetMyBadgesRequest {
    return {};
  },

  toJSON(_: GetMyBadgesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyBadgesRequest>, I>>(base?: I): GetMyBadgesRequest {
    return GetMyBadgesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyBadgesRequest>, I>>(_: I): GetMyBadgesRequest {
    const message = createBaseGetMyBadgesRequest();
    return message;
  },
};

function createBaseGetMyBadgesResponse(): GetMyBadgesResponse {
  return { status: 0, message: '', badges: [] };
}

export const GetMyBadgesResponse = {
  encode(message: GetMyBadgesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.badges) {
      Badge.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyBadgesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyBadgesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.badges.push(Badge.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyBadgesResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      badges: globalThis.Array.isArray(object?.badges)
        ? object.badges.map((e: any) => Badge.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetMyBadgesResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.badges?.length) {
      obj.badges = message.badges.map((e) => Badge.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyBadgesResponse>, I>>(base?: I): GetMyBadgesResponse {
    return GetMyBadgesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyBadgesResponse>, I>>(
    object: I,
  ): GetMyBadgesResponse {
    const message = createBaseGetMyBadgesResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.badges = object.badges?.map((e) => Badge.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetMyInterestsRequest(): GetMyInterestsRequest {
  return {};
}

export const GetMyInterestsRequest = {
  encode(_: GetMyInterestsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInterestsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInterestsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetMyInterestsRequest {
    return {};
  },

  toJSON(_: GetMyInterestsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInterestsRequest>, I>>(base?: I): GetMyInterestsRequest {
    return GetMyInterestsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInterestsRequest>, I>>(_: I): GetMyInterestsRequest {
    const message = createBaseGetMyInterestsRequest();
    return message;
  },
};

function createBaseGetMyInterestsResponse(): GetMyInterestsResponse {
  return { status: 0, message: '', interests: [] };
}

export const GetMyInterestsResponse = {
  encode(message: GetMyInterestsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyInterestsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyInterestsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.interests.push(Interest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyInterestsResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      interests: globalThis.Array.isArray(object?.interests)
        ? object.interests.map((e: any) => Interest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetMyInterestsResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.interests?.length) {
      obj.interests = message.interests.map((e) => Interest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyInterestsResponse>, I>>(
    base?: I,
  ): GetMyInterestsResponse {
    return GetMyInterestsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyInterestsResponse>, I>>(
    object: I,
  ): GetMyInterestsResponse {
    const message = createBaseGetMyInterestsResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.interests = object.interests?.map((e) => Interest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetMyImpersonateesRequest(): GetMyImpersonateesRequest {
  return {};
}

export const GetMyImpersonateesRequest = {
  encode(_: GetMyImpersonateesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyImpersonateesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyImpersonateesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetMyImpersonateesRequest {
    return {};
  },

  toJSON(_: GetMyImpersonateesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyImpersonateesRequest>, I>>(
    base?: I,
  ): GetMyImpersonateesRequest {
    return GetMyImpersonateesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyImpersonateesRequest>, I>>(
    _: I,
  ): GetMyImpersonateesRequest {
    const message = createBaseGetMyImpersonateesRequest();
    return message;
  },
};

function createBaseGetMyImpersonateesResponse(): GetMyImpersonateesResponse {
  return { status: 0, message: '', isAllowedAll: false, impersonatees: [] };
}

export const GetMyImpersonateesResponse = {
  encode(
    message: GetMyImpersonateesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.isAllowedAll === true) {
      writer.uint32(24).bool(message.isAllowedAll);
    }
    for (const v of message.impersonatees) {
      EmployeeFound.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyImpersonateesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMyImpersonateesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isAllowedAll = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.impersonatees.push(EmployeeFound.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMyImpersonateesResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      isAllowedAll: isSet(object.isAllowedAll) ? globalThis.Boolean(object.isAllowedAll) : false,
      impersonatees: globalThis.Array.isArray(object?.impersonatees)
        ? object.impersonatees.map((e: any) => EmployeeFound.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetMyImpersonateesResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.isAllowedAll === true) {
      obj.isAllowedAll = message.isAllowedAll;
    }
    if (message.impersonatees?.length) {
      obj.impersonatees = message.impersonatees.map((e) => EmployeeFound.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMyImpersonateesResponse>, I>>(
    base?: I,
  ): GetMyImpersonateesResponse {
    return GetMyImpersonateesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMyImpersonateesResponse>, I>>(
    object: I,
  ): GetMyImpersonateesResponse {
    const message = createBaseGetMyImpersonateesResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.isAllowedAll = object.isAllowedAll ?? false;
    message.impersonatees = object.impersonatees?.map((e) => EmployeeFound.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetWallConfigRequest(): GetWallConfigRequest {
  return {};
}

export const GetWallConfigRequest = {
  encode(_: GetWallConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWallConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWallConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetWallConfigRequest {
    return {};
  },

  toJSON(_: GetWallConfigRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWallConfigRequest>, I>>(base?: I): GetWallConfigRequest {
    return GetWallConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWallConfigRequest>, I>>(_: I): GetWallConfigRequest {
    const message = createBaseGetWallConfigRequest();
    return message;
  },
};

function createBaseGetWallConfigResponse(): GetWallConfigResponse {
  return { status: 0, message: '', data: undefined };
}

export const GetWallConfigResponse = {
  encode(message: GetWallConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.data !== undefined) {
      WallConfig.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWallConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWallConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = WallConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWallConfigResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      data: isSet(object.data) ? WallConfig.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: GetWallConfigResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.data !== undefined) {
      obj.data = WallConfig.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWallConfigResponse>, I>>(base?: I): GetWallConfigResponse {
    return GetWallConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWallConfigResponse>, I>>(
    object: I,
  ): GetWallConfigResponse {
    const message = createBaseGetWallConfigResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.data =
      object.data !== undefined && object.data !== null
        ? WallConfig.fromPartial(object.data)
        : undefined;
    return message;
  },
};

function createBaseGetWallDetailRequest(): GetWallDetailRequest {
  return { username: '' };
}

export const GetWallDetailRequest = {
  encode(message: GetWallDetailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== '') {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWallDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWallDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWallDetailRequest {
    return { username: isSet(object.username) ? globalThis.String(object.username) : '' };
  },

  toJSON(message: GetWallDetailRequest): unknown {
    const obj: any = {};
    if (message.username !== '') {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWallDetailRequest>, I>>(base?: I): GetWallDetailRequest {
    return GetWallDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWallDetailRequest>, I>>(
    object: I,
  ): GetWallDetailRequest {
    const message = createBaseGetWallDetailRequest();
    message.username = object.username ?? '';
    return message;
  },
};

function createBaseGetWallDetailResponse(): GetWallDetailResponse {
  return { status: 0, message: '', data: undefined };
}

export const GetWallDetailResponse = {
  encode(message: GetWallDetailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.data !== undefined) {
      WallDetail.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWallDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWallDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = WallDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWallDetailResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      data: isSet(object.data) ? WallDetail.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: GetWallDetailResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.data !== undefined) {
      obj.data = WallDetail.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWallDetailResponse>, I>>(base?: I): GetWallDetailResponse {
    return GetWallDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWallDetailResponse>, I>>(
    object: I,
  ): GetWallDetailResponse {
    const message = createBaseGetWallDetailResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.data =
      object.data !== undefined && object.data !== null
        ? WallDetail.fromPartial(object.data)
        : undefined;
    return message;
  },
};

function createBaseUpdateEmployeePronounRequest(): UpdateEmployeePronounRequest {
  return { pronounId: '0' };
}

export const UpdateEmployeePronounRequest = {
  encode(
    message: UpdateEmployeePronounRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.pronounId !== '0') {
      writer.uint32(8).int64(message.pronounId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeePronounRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeePronounRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pronounId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeePronounRequest {
    return { pronounId: isSet(object.pronounId) ? globalThis.String(object.pronounId) : '0' };
  },

  toJSON(message: UpdateEmployeePronounRequest): unknown {
    const obj: any = {};
    if (message.pronounId !== '0') {
      obj.pronounId = message.pronounId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeePronounRequest>, I>>(
    base?: I,
  ): UpdateEmployeePronounRequest {
    return UpdateEmployeePronounRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeePronounRequest>, I>>(
    object: I,
  ): UpdateEmployeePronounRequest {
    const message = createBaseUpdateEmployeePronounRequest();
    message.pronounId = object.pronounId ?? '0';
    return message;
  },
};

function createBaseUpdateEmployeePronounResponse(): UpdateEmployeePronounResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeePronounResponse = {
  encode(
    message: UpdateEmployeePronounResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeePronounResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeePronounResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeePronounResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeePronounResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeePronounResponse>, I>>(
    base?: I,
  ): UpdateEmployeePronounResponse {
    return UpdateEmployeePronounResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeePronounResponse>, I>>(
    object: I,
  ): UpdateEmployeePronounResponse {
    const message = createBaseUpdateEmployeePronounResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseUpdateEmployeeFavoriteCoreValueRequest(): UpdateEmployeeFavoriteCoreValueRequest {
  return { coreValueId: '0' };
}

export const UpdateEmployeeFavoriteCoreValueRequest = {
  encode(
    message: UpdateEmployeeFavoriteCoreValueRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.coreValueId !== '0') {
      writer.uint32(8).int64(message.coreValueId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeFavoriteCoreValueRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeFavoriteCoreValueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.coreValueId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeFavoriteCoreValueRequest {
    return { coreValueId: isSet(object.coreValueId) ? globalThis.String(object.coreValueId) : '0' };
  },

  toJSON(message: UpdateEmployeeFavoriteCoreValueRequest): unknown {
    const obj: any = {};
    if (message.coreValueId !== '0') {
      obj.coreValueId = message.coreValueId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeFavoriteCoreValueRequest>, I>>(
    base?: I,
  ): UpdateEmployeeFavoriteCoreValueRequest {
    return UpdateEmployeeFavoriteCoreValueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeFavoriteCoreValueRequest>, I>>(
    object: I,
  ): UpdateEmployeeFavoriteCoreValueRequest {
    const message = createBaseUpdateEmployeeFavoriteCoreValueRequest();
    message.coreValueId = object.coreValueId ?? '0';
    return message;
  },
};

function createBaseUpdateEmployeeFavoriteCoreValueResponse(): UpdateEmployeeFavoriteCoreValueResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeeFavoriteCoreValueResponse = {
  encode(
    message: UpdateEmployeeFavoriteCoreValueResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeFavoriteCoreValueResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeFavoriteCoreValueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeFavoriteCoreValueResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeeFavoriteCoreValueResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeFavoriteCoreValueResponse>, I>>(
    base?: I,
  ): UpdateEmployeeFavoriteCoreValueResponse {
    return UpdateEmployeeFavoriteCoreValueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeFavoriteCoreValueResponse>, I>>(
    object: I,
  ): UpdateEmployeeFavoriteCoreValueResponse {
    const message = createBaseUpdateEmployeeFavoriteCoreValueResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseUpdateEmployeeFunFactRequest(): UpdateEmployeeFunFactRequest {
  return { funFactId: '0', input: '' };
}

export const UpdateEmployeeFunFactRequest = {
  encode(
    message: UpdateEmployeeFunFactRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.funFactId !== '0') {
      writer.uint32(8).int64(message.funFactId);
    }
    if (message.input !== '') {
      writer.uint32(18).string(message.input);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeFunFactRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeFunFactRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.funFactId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.input = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeFunFactRequest {
    return {
      funFactId: isSet(object.funFactId) ? globalThis.String(object.funFactId) : '0',
      input: isSet(object.input) ? globalThis.String(object.input) : '',
    };
  },

  toJSON(message: UpdateEmployeeFunFactRequest): unknown {
    const obj: any = {};
    if (message.funFactId !== '0') {
      obj.funFactId = message.funFactId;
    }
    if (message.input !== '') {
      obj.input = message.input;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeFunFactRequest>, I>>(
    base?: I,
  ): UpdateEmployeeFunFactRequest {
    return UpdateEmployeeFunFactRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeFunFactRequest>, I>>(
    object: I,
  ): UpdateEmployeeFunFactRequest {
    const message = createBaseUpdateEmployeeFunFactRequest();
    message.funFactId = object.funFactId ?? '0';
    message.input = object.input ?? '';
    return message;
  },
};

function createBaseUpdateEmployeeFunFactResponse(): UpdateEmployeeFunFactResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeeFunFactResponse = {
  encode(
    message: UpdateEmployeeFunFactResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeFunFactResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeFunFactResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeFunFactResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeeFunFactResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeFunFactResponse>, I>>(
    base?: I,
  ): UpdateEmployeeFunFactResponse {
    return UpdateEmployeeFunFactResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeFunFactResponse>, I>>(
    object: I,
  ): UpdateEmployeeFunFactResponse {
    const message = createBaseUpdateEmployeeFunFactResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseUpdateEmployeePrimaryContactNumberRequest(): UpdateEmployeePrimaryContactNumberRequest {
  return { primaryContactNumber: '' };
}

export const UpdateEmployeePrimaryContactNumberRequest = {
  encode(
    message: UpdateEmployeePrimaryContactNumberRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.primaryContactNumber !== '') {
      writer.uint32(10).string(message.primaryContactNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateEmployeePrimaryContactNumberRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeePrimaryContactNumberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primaryContactNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeePrimaryContactNumberRequest {
    return {
      primaryContactNumber: isSet(object.primaryContactNumber)
        ? globalThis.String(object.primaryContactNumber)
        : '',
    };
  },

  toJSON(message: UpdateEmployeePrimaryContactNumberRequest): unknown {
    const obj: any = {};
    if (message.primaryContactNumber !== '') {
      obj.primaryContactNumber = message.primaryContactNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeePrimaryContactNumberRequest>, I>>(
    base?: I,
  ): UpdateEmployeePrimaryContactNumberRequest {
    return UpdateEmployeePrimaryContactNumberRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeePrimaryContactNumberRequest>, I>>(
    object: I,
  ): UpdateEmployeePrimaryContactNumberRequest {
    const message = createBaseUpdateEmployeePrimaryContactNumberRequest();
    message.primaryContactNumber = object.primaryContactNumber ?? '';
    return message;
  },
};

function createBaseUpdateEmployeePrimaryContactNumberResponse(): UpdateEmployeePrimaryContactNumberResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeePrimaryContactNumberResponse = {
  encode(
    message: UpdateEmployeePrimaryContactNumberResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateEmployeePrimaryContactNumberResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeePrimaryContactNumberResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeePrimaryContactNumberResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeePrimaryContactNumberResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeePrimaryContactNumberResponse>, I>>(
    base?: I,
  ): UpdateEmployeePrimaryContactNumberResponse {
    return UpdateEmployeePrimaryContactNumberResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeePrimaryContactNumberResponse>, I>>(
    object: I,
  ): UpdateEmployeePrimaryContactNumberResponse {
    const message = createBaseUpdateEmployeePrimaryContactNumberResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseUpdateEmployeeNamePronunciationRequest(): UpdateEmployeeNamePronunciationRequest {
  return { audio: new Uint8Array(0), description: undefined, deleteAudio: false };
}

export const UpdateEmployeeNamePronunciationRequest = {
  encode(
    message: UpdateEmployeeNamePronunciationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.audio.length !== 0) {
      writer.uint32(10).bytes(message.audio);
    }
    if (message.description !== undefined) {
      StringValue.encode({ value: message.description! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.deleteAudio === true) {
      writer.uint32(24).bool(message.deleteAudio);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeNamePronunciationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeNamePronunciationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.audio = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleteAudio = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeNamePronunciationRequest {
    return {
      audio: isSet(object.audio) ? bytesFromBase64(object.audio) : new Uint8Array(0),
      description: isSet(object.description) ? String(object.description) : undefined,
      deleteAudio: isSet(object.deleteAudio) ? globalThis.Boolean(object.deleteAudio) : false,
    };
  },

  toJSON(message: UpdateEmployeeNamePronunciationRequest): unknown {
    const obj: any = {};
    if (message.audio.length !== 0) {
      obj.audio = base64FromBytes(message.audio);
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.deleteAudio === true) {
      obj.deleteAudio = message.deleteAudio;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeNamePronunciationRequest>, I>>(
    base?: I,
  ): UpdateEmployeeNamePronunciationRequest {
    return UpdateEmployeeNamePronunciationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeNamePronunciationRequest>, I>>(
    object: I,
  ): UpdateEmployeeNamePronunciationRequest {
    const message = createBaseUpdateEmployeeNamePronunciationRequest();
    message.audio = object.audio ?? new Uint8Array(0);
    message.description = object.description ?? undefined;
    message.deleteAudio = object.deleteAudio ?? false;
    return message;
  },
};

function createBaseUpdateEmployeeNamePronunciationResponse(): UpdateEmployeeNamePronunciationResponse {
  return { status: 0, message: '', namePronunciation: undefined };
}

export const UpdateEmployeeNamePronunciationResponse = {
  encode(
    message: UpdateEmployeeNamePronunciationResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.namePronunciation !== undefined) {
      NamePronunciation.encode(message.namePronunciation, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeNamePronunciationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeNamePronunciationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.namePronunciation = NamePronunciation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeNamePronunciationResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      namePronunciation: isSet(object.namePronunciation)
        ? NamePronunciation.fromJSON(object.namePronunciation)
        : undefined,
    };
  },

  toJSON(message: UpdateEmployeeNamePronunciationResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.namePronunciation !== undefined) {
      obj.namePronunciation = NamePronunciation.toJSON(message.namePronunciation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeNamePronunciationResponse>, I>>(
    base?: I,
  ): UpdateEmployeeNamePronunciationResponse {
    return UpdateEmployeeNamePronunciationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeNamePronunciationResponse>, I>>(
    object: I,
  ): UpdateEmployeeNamePronunciationResponse {
    const message = createBaseUpdateEmployeeNamePronunciationResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.namePronunciation =
      object.namePronunciation !== undefined && object.namePronunciation !== null
        ? NamePronunciation.fromPartial(object.namePronunciation)
        : undefined;
    return message;
  },
};

function createBaseUpdateEmployeeLinkedInProfileUrlRequest(): UpdateEmployeeLinkedInProfileUrlRequest {
  return { linkedinProfileUrl: '' };
}

export const UpdateEmployeeLinkedInProfileUrlRequest = {
  encode(
    message: UpdateEmployeeLinkedInProfileUrlRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.linkedinProfileUrl !== '') {
      writer.uint32(10).string(message.linkedinProfileUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeLinkedInProfileUrlRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeLinkedInProfileUrlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedinProfileUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeLinkedInProfileUrlRequest {
    return {
      linkedinProfileUrl: isSet(object.linkedinProfileUrl)
        ? globalThis.String(object.linkedinProfileUrl)
        : '',
    };
  },

  toJSON(message: UpdateEmployeeLinkedInProfileUrlRequest): unknown {
    const obj: any = {};
    if (message.linkedinProfileUrl !== '') {
      obj.linkedinProfileUrl = message.linkedinProfileUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeLinkedInProfileUrlRequest>, I>>(
    base?: I,
  ): UpdateEmployeeLinkedInProfileUrlRequest {
    return UpdateEmployeeLinkedInProfileUrlRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeLinkedInProfileUrlRequest>, I>>(
    object: I,
  ): UpdateEmployeeLinkedInProfileUrlRequest {
    const message = createBaseUpdateEmployeeLinkedInProfileUrlRequest();
    message.linkedinProfileUrl = object.linkedinProfileUrl ?? '';
    return message;
  },
};

function createBaseUpdateEmployeeLinkedInProfileUrlResponse(): UpdateEmployeeLinkedInProfileUrlResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeeLinkedInProfileUrlResponse = {
  encode(
    message: UpdateEmployeeLinkedInProfileUrlResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateEmployeeLinkedInProfileUrlResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeLinkedInProfileUrlResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeLinkedInProfileUrlResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeeLinkedInProfileUrlResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeLinkedInProfileUrlResponse>, I>>(
    base?: I,
  ): UpdateEmployeeLinkedInProfileUrlResponse {
    return UpdateEmployeeLinkedInProfileUrlResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeLinkedInProfileUrlResponse>, I>>(
    object: I,
  ): UpdateEmployeeLinkedInProfileUrlResponse {
    const message = createBaseUpdateEmployeeLinkedInProfileUrlResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseGetEmployeeKarmasRequest(): GetEmployeeKarmasRequest {
  return { employeeId: '0', karmaType: 0, start: '0', size: '0' };
}

export const GetEmployeeKarmasRequest = {
  encode(message: GetEmployeeKarmasRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.employeeId !== '0') {
      writer.uint32(8).int64(message.employeeId);
    }
    if (message.karmaType !== 0) {
      writer.uint32(16).int32(message.karmaType);
    }
    if (message.start !== '0') {
      writer.uint32(24).int64(message.start);
    }
    if (message.size !== '0') {
      writer.uint32(32).int64(message.size);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeeKarmasRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmployeeKarmasRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.employeeId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.karmaType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.start = longToString(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.size = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmployeeKarmasRequest {
    return {
      employeeId: isSet(object.employeeId) ? globalThis.String(object.employeeId) : '0',
      karmaType: isSet(object.karmaType)
        ? getEmployeeKarmasRequest_KarmaTypeFromJSON(object.karmaType)
        : 0,
      start: isSet(object.start) ? globalThis.String(object.start) : '0',
      size: isSet(object.size) ? globalThis.String(object.size) : '0',
    };
  },

  toJSON(message: GetEmployeeKarmasRequest): unknown {
    const obj: any = {};
    if (message.employeeId !== '0') {
      obj.employeeId = message.employeeId;
    }
    if (message.karmaType !== 0) {
      obj.karmaType = getEmployeeKarmasRequest_KarmaTypeToJSON(message.karmaType);
    }
    if (message.start !== '0') {
      obj.start = message.start;
    }
    if (message.size !== '0') {
      obj.size = message.size;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmployeeKarmasRequest>, I>>(
    base?: I,
  ): GetEmployeeKarmasRequest {
    return GetEmployeeKarmasRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmployeeKarmasRequest>, I>>(
    object: I,
  ): GetEmployeeKarmasRequest {
    const message = createBaseGetEmployeeKarmasRequest();
    message.employeeId = object.employeeId ?? '0';
    message.karmaType = object.karmaType ?? 0;
    message.start = object.start ?? '0';
    message.size = object.size ?? '0';
    return message;
  },
};

function createBaseGetEmployeeKarmasResponse(): GetEmployeeKarmasResponse {
  return { status: 0, message: '', karmas: [] };
}

export const GetEmployeeKarmasResponse = {
  encode(message: GetEmployeeKarmasResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.karmas) {
      Karma.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeeKarmasResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmployeeKarmasResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.karmas.push(Karma.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmployeeKarmasResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      karmas: globalThis.Array.isArray(object?.karmas)
        ? object.karmas.map((e: any) => Karma.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetEmployeeKarmasResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.karmas?.length) {
      obj.karmas = message.karmas.map((e) => Karma.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmployeeKarmasResponse>, I>>(
    base?: I,
  ): GetEmployeeKarmasResponse {
    return GetEmployeeKarmasResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmployeeKarmasResponse>, I>>(
    object: I,
  ): GetEmployeeKarmasResponse {
    const message = createBaseGetEmployeeKarmasResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.karmas = object.karmas?.map((e) => Karma.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteEmployeeKarmaRequest(): DeleteEmployeeKarmaRequest {
  return { karmaId: '0' };
}

export const DeleteEmployeeKarmaRequest = {
  encode(
    message: DeleteEmployeeKarmaRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.karmaId !== '0') {
      writer.uint32(8).int64(message.karmaId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteEmployeeKarmaRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteEmployeeKarmaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.karmaId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteEmployeeKarmaRequest {
    return { karmaId: isSet(object.karmaId) ? globalThis.String(object.karmaId) : '0' };
  },

  toJSON(message: DeleteEmployeeKarmaRequest): unknown {
    const obj: any = {};
    if (message.karmaId !== '0') {
      obj.karmaId = message.karmaId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteEmployeeKarmaRequest>, I>>(
    base?: I,
  ): DeleteEmployeeKarmaRequest {
    return DeleteEmployeeKarmaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteEmployeeKarmaRequest>, I>>(
    object: I,
  ): DeleteEmployeeKarmaRequest {
    const message = createBaseDeleteEmployeeKarmaRequest();
    message.karmaId = object.karmaId ?? '0';
    return message;
  },
};

function createBaseDeleteEmployeeKarmaResponse(): DeleteEmployeeKarmaResponse {
  return { status: 0, message: '' };
}

export const DeleteEmployeeKarmaResponse = {
  encode(
    message: DeleteEmployeeKarmaResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteEmployeeKarmaResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteEmployeeKarmaResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteEmployeeKarmaResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: DeleteEmployeeKarmaResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteEmployeeKarmaResponse>, I>>(
    base?: I,
  ): DeleteEmployeeKarmaResponse {
    return DeleteEmployeeKarmaResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteEmployeeKarmaResponse>, I>>(
    object: I,
  ): DeleteEmployeeKarmaResponse {
    const message = createBaseDeleteEmployeeKarmaResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseGiveKarmaRequest(): GiveKarmaRequest {
  return { receiverId: '0', message: '', coreValueId: undefined, leadershipBlueprintId: undefined };
}

export const GiveKarmaRequest = {
  encode(message: GiveKarmaRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receiverId !== '0') {
      writer.uint32(8).int64(message.receiverId);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.coreValueId !== undefined) {
      Int64Value.encode({ value: message.coreValueId! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.leadershipBlueprintId !== undefined) {
      Int64Value.encode(
        { value: message.leadershipBlueprintId! },
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GiveKarmaRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGiveKarmaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.receiverId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.coreValueId = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.leadershipBlueprintId = Int64Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GiveKarmaRequest {
    return {
      receiverId: isSet(object.receiverId) ? globalThis.String(object.receiverId) : '0',
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      coreValueId: isSet(object.coreValueId) ? String(object.coreValueId) : undefined,
      leadershipBlueprintId: isSet(object.leadershipBlueprintId)
        ? String(object.leadershipBlueprintId)
        : undefined,
    };
  },

  toJSON(message: GiveKarmaRequest): unknown {
    const obj: any = {};
    if (message.receiverId !== '0') {
      obj.receiverId = message.receiverId;
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.coreValueId !== undefined) {
      obj.coreValueId = message.coreValueId;
    }
    if (message.leadershipBlueprintId !== undefined) {
      obj.leadershipBlueprintId = message.leadershipBlueprintId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GiveKarmaRequest>, I>>(base?: I): GiveKarmaRequest {
    return GiveKarmaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GiveKarmaRequest>, I>>(object: I): GiveKarmaRequest {
    const message = createBaseGiveKarmaRequest();
    message.receiverId = object.receiverId ?? '0';
    message.message = object.message ?? '';
    message.coreValueId = object.coreValueId ?? undefined;
    message.leadershipBlueprintId = object.leadershipBlueprintId ?? undefined;
    return message;
  },
};

function createBaseGiveKarmaResponse(): GiveKarmaResponse {
  return { status: 0, message: '', karmaId: '0' };
}

export const GiveKarmaResponse = {
  encode(message: GiveKarmaResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.karmaId !== '0') {
      writer.uint32(24).int64(message.karmaId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GiveKarmaResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGiveKarmaResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.karmaId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GiveKarmaResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      karmaId: isSet(object.karmaId) ? globalThis.String(object.karmaId) : '0',
    };
  },

  toJSON(message: GiveKarmaResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.karmaId !== '0') {
      obj.karmaId = message.karmaId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GiveKarmaResponse>, I>>(base?: I): GiveKarmaResponse {
    return GiveKarmaResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GiveKarmaResponse>, I>>(object: I): GiveKarmaResponse {
    const message = createBaseGiveKarmaResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.karmaId = object.karmaId ?? '0';
    return message;
  },
};

function createBaseGetInsightsTwinsRequest(): GetInsightsTwinsRequest {
  return { username: '' };
}

export const GetInsightsTwinsRequest = {
  encode(message: GetInsightsTwinsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== '') {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInsightsTwinsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInsightsTwinsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInsightsTwinsRequest {
    return { username: isSet(object.username) ? globalThis.String(object.username) : '' };
  },

  toJSON(message: GetInsightsTwinsRequest): unknown {
    const obj: any = {};
    if (message.username !== '') {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInsightsTwinsRequest>, I>>(
    base?: I,
  ): GetInsightsTwinsRequest {
    return GetInsightsTwinsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInsightsTwinsRequest>, I>>(
    object: I,
  ): GetInsightsTwinsRequest {
    const message = createBaseGetInsightsTwinsRequest();
    message.username = object.username ?? '';
    return message;
  },
};

function createBaseGetInsightsTwinsResponse(): GetInsightsTwinsResponse {
  return { status: 0, message: '', employee: undefined, insightTwins: [] };
}

export const GetInsightsTwinsResponse = {
  encode(message: GetInsightsTwinsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.employee !== undefined) {
      Employee.encode(message.employee, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.insightTwins) {
      Employee.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInsightsTwinsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInsightsTwinsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.employee = Employee.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.insightTwins.push(Employee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInsightsTwinsResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      employee: isSet(object.employee) ? Employee.fromJSON(object.employee) : undefined,
      insightTwins: globalThis.Array.isArray(object?.insightTwins)
        ? object.insightTwins.map((e: any) => Employee.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetInsightsTwinsResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.employee !== undefined) {
      obj.employee = Employee.toJSON(message.employee);
    }
    if (message.insightTwins?.length) {
      obj.insightTwins = message.insightTwins.map((e) => Employee.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInsightsTwinsResponse>, I>>(
    base?: I,
  ): GetInsightsTwinsResponse {
    return GetInsightsTwinsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInsightsTwinsResponse>, I>>(
    object: I,
  ): GetInsightsTwinsResponse {
    const message = createBaseGetInsightsTwinsResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.employee =
      object.employee !== undefined && object.employee !== null
        ? Employee.fromPartial(object.employee)
        : undefined;
    message.insightTwins = object.insightTwins?.map((e) => Employee.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetEmployeesWithInsightsColorRequest(): GetEmployeesWithInsightsColorRequest {
  return { color: '' };
}

export const GetEmployeesWithInsightsColorRequest = {
  encode(
    message: GetEmployeesWithInsightsColorRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.color !== '') {
      writer.uint32(10).string(message.color);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeesWithInsightsColorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmployeesWithInsightsColorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.color = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmployeesWithInsightsColorRequest {
    return { color: isSet(object.color) ? globalThis.String(object.color) : '' };
  },

  toJSON(message: GetEmployeesWithInsightsColorRequest): unknown {
    const obj: any = {};
    if (message.color !== '') {
      obj.color = message.color;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmployeesWithInsightsColorRequest>, I>>(
    base?: I,
  ): GetEmployeesWithInsightsColorRequest {
    return GetEmployeesWithInsightsColorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmployeesWithInsightsColorRequest>, I>>(
    object: I,
  ): GetEmployeesWithInsightsColorRequest {
    const message = createBaseGetEmployeesWithInsightsColorRequest();
    message.color = object.color ?? '';
    return message;
  },
};

function createBaseGetEmployeesWithInsightsColorResponse(): GetEmployeesWithInsightsColorResponse {
  return { status: 0, message: '', employees: [] };
}

export const GetEmployeesWithInsightsColorResponse = {
  encode(
    message: GetEmployeesWithInsightsColorResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.employees) {
      Employee.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeesWithInsightsColorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmployeesWithInsightsColorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.employees.push(Employee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmployeesWithInsightsColorResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      employees: globalThis.Array.isArray(object?.employees)
        ? object.employees.map((e: any) => Employee.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetEmployeesWithInsightsColorResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.employees?.length) {
      obj.employees = message.employees.map((e) => Employee.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmployeesWithInsightsColorResponse>, I>>(
    base?: I,
  ): GetEmployeesWithInsightsColorResponse {
    return GetEmployeesWithInsightsColorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmployeesWithInsightsColorResponse>, I>>(
    object: I,
  ): GetEmployeesWithInsightsColorResponse {
    const message = createBaseGetEmployeesWithInsightsColorResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.employees = object.employees?.map((e) => Employee.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateEmployeeInsightsRequest(): UpdateEmployeeInsightsRequest {
  return { insights: undefined };
}

export const UpdateEmployeeInsightsRequest = {
  encode(
    message: UpdateEmployeeInsightsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.insights !== undefined) {
      Insights.encode(message.insights, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeInsightsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeInsightsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.insights = Insights.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeInsightsRequest {
    return { insights: isSet(object.insights) ? Insights.fromJSON(object.insights) : undefined };
  },

  toJSON(message: UpdateEmployeeInsightsRequest): unknown {
    const obj: any = {};
    if (message.insights !== undefined) {
      obj.insights = Insights.toJSON(message.insights);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeInsightsRequest>, I>>(
    base?: I,
  ): UpdateEmployeeInsightsRequest {
    return UpdateEmployeeInsightsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeInsightsRequest>, I>>(
    object: I,
  ): UpdateEmployeeInsightsRequest {
    const message = createBaseUpdateEmployeeInsightsRequest();
    message.insights =
      object.insights !== undefined && object.insights !== null
        ? Insights.fromPartial(object.insights)
        : undefined;
    return message;
  },
};

function createBaseUpdateEmployeeInsightsResponse(): UpdateEmployeeInsightsResponse {
  return { status: 0, message: '' };
}

export const UpdateEmployeeInsightsResponse = {
  encode(
    message: UpdateEmployeeInsightsResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmployeeInsightsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmployeeInsightsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmployeeInsightsResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: UpdateEmployeeInsightsResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmployeeInsightsResponse>, I>>(
    base?: I,
  ): UpdateEmployeeInsightsResponse {
    return UpdateEmployeeInsightsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEmployeeInsightsResponse>, I>>(
    object: I,
  ): UpdateEmployeeInsightsResponse {
    const message = createBaseUpdateEmployeeInsightsResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseGetInterestWallDetailRequest(): GetInterestWallDetailRequest {
  return { interestId: '0' };
}

export const GetInterestWallDetailRequest = {
  encode(
    message: GetInterestWallDetailRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.interestId !== '0') {
      writer.uint32(8).int64(message.interestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInterestWallDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInterestWallDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.interestId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInterestWallDetailRequest {
    return { interestId: isSet(object.interestId) ? globalThis.String(object.interestId) : '0' };
  },

  toJSON(message: GetInterestWallDetailRequest): unknown {
    const obj: any = {};
    if (message.interestId !== '0') {
      obj.interestId = message.interestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInterestWallDetailRequest>, I>>(
    base?: I,
  ): GetInterestWallDetailRequest {
    return GetInterestWallDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInterestWallDetailRequest>, I>>(
    object: I,
  ): GetInterestWallDetailRequest {
    const message = createBaseGetInterestWallDetailRequest();
    message.interestId = object.interestId ?? '0';
    return message;
  },
};

function createBaseGetInterestWallDetailResponse(): GetInterestWallDetailResponse {
  return { status: 0, message: '', interest: undefined, assignees: [] };
}

export const GetInterestWallDetailResponse = {
  encode(
    message: GetInterestWallDetailResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.interest !== undefined) {
      Interest.encode(message.interest, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.assignees) {
      Employee.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInterestWallDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInterestWallDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.interest = Interest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.assignees.push(Employee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInterestWallDetailResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      interest: isSet(object.interest) ? Interest.fromJSON(object.interest) : undefined,
      assignees: globalThis.Array.isArray(object?.assignees)
        ? object.assignees.map((e: any) => Employee.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetInterestWallDetailResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.interest !== undefined) {
      obj.interest = Interest.toJSON(message.interest);
    }
    if (message.assignees?.length) {
      obj.assignees = message.assignees.map((e) => Employee.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInterestWallDetailResponse>, I>>(
    base?: I,
  ): GetInterestWallDetailResponse {
    return GetInterestWallDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInterestWallDetailResponse>, I>>(
    object: I,
  ): GetInterestWallDetailResponse {
    const message = createBaseGetInterestWallDetailResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.interest =
      object.interest !== undefined && object.interest !== null
        ? Interest.fromPartial(object.interest)
        : undefined;
    message.assignees = object.assignees?.map((e) => Employee.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAllInterestsRequest(): GetAllInterestsRequest {
  return {};
}

export const GetAllInterestsRequest = {
  encode(_: GetAllInterestsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllInterestsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllInterestsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAllInterestsRequest {
    return {};
  },

  toJSON(_: GetAllInterestsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllInterestsRequest>, I>>(
    base?: I,
  ): GetAllInterestsRequest {
    return GetAllInterestsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllInterestsRequest>, I>>(
    _: I,
  ): GetAllInterestsRequest {
    const message = createBaseGetAllInterestsRequest();
    return message;
  },
};

function createBaseGetAllInterestsResponse(): GetAllInterestsResponse {
  return { status: 0, message: '', interests: [] };
}

export const GetAllInterestsResponse = {
  encode(message: GetAllInterestsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllInterestsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllInterestsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.interests.push(Interest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllInterestsResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      interests: globalThis.Array.isArray(object?.interests)
        ? object.interests.map((e: any) => Interest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllInterestsResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.interests?.length) {
      obj.interests = message.interests.map((e) => Interest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllInterestsResponse>, I>>(
    base?: I,
  ): GetAllInterestsResponse {
    return GetAllInterestsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllInterestsResponse>, I>>(
    object: I,
  ): GetAllInterestsResponse {
    const message = createBaseGetAllInterestsResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.interests = object.interests?.map((e) => Interest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateInterestRequest(): CreateInterestRequest {
  return { name: '', description: '', iconImage: new Uint8Array(0) };
}

export const CreateInterestRequest = {
  encode(message: CreateInterestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    if (message.iconImage.length !== 0) {
      writer.uint32(26).bytes(message.iconImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateInterestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateInterestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.iconImage = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateInterestRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      description: isSet(object.description) ? globalThis.String(object.description) : '',
      iconImage: isSet(object.iconImage) ? bytesFromBase64(object.iconImage) : new Uint8Array(0),
    };
  },

  toJSON(message: CreateInterestRequest): unknown {
    const obj: any = {};
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.description !== '') {
      obj.description = message.description;
    }
    if (message.iconImage.length !== 0) {
      obj.iconImage = base64FromBytes(message.iconImage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateInterestRequest>, I>>(base?: I): CreateInterestRequest {
    return CreateInterestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateInterestRequest>, I>>(
    object: I,
  ): CreateInterestRequest {
    const message = createBaseCreateInterestRequest();
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.iconImage = object.iconImage ?? new Uint8Array(0);
    return message;
  },
};

function createBaseCreateInterestResponse(): CreateInterestResponse {
  return { status: 0, message: '', interestId: '0' };
}

export const CreateInterestResponse = {
  encode(message: CreateInterestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.interestId !== '0') {
      writer.uint32(24).int64(message.interestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateInterestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateInterestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.interestId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateInterestResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      interestId: isSet(object.interestId) ? globalThis.String(object.interestId) : '0',
    };
  },

  toJSON(message: CreateInterestResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.interestId !== '0') {
      obj.interestId = message.interestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateInterestResponse>, I>>(
    base?: I,
  ): CreateInterestResponse {
    return CreateInterestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateInterestResponse>, I>>(
    object: I,
  ): CreateInterestResponse {
    const message = createBaseCreateInterestResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.interestId = object.interestId ?? '0';
    return message;
  },
};

function createBaseAddEmployeeInterestRequest(): AddEmployeeInterestRequest {
  return { interestId: '0' };
}

export const AddEmployeeInterestRequest = {
  encode(
    message: AddEmployeeInterestRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.interestId !== '0') {
      writer.uint32(8).int64(message.interestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddEmployeeInterestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddEmployeeInterestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.interestId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddEmployeeInterestRequest {
    return { interestId: isSet(object.interestId) ? globalThis.String(object.interestId) : '0' };
  },

  toJSON(message: AddEmployeeInterestRequest): unknown {
    const obj: any = {};
    if (message.interestId !== '0') {
      obj.interestId = message.interestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddEmployeeInterestRequest>, I>>(
    base?: I,
  ): AddEmployeeInterestRequest {
    return AddEmployeeInterestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddEmployeeInterestRequest>, I>>(
    object: I,
  ): AddEmployeeInterestRequest {
    const message = createBaseAddEmployeeInterestRequest();
    message.interestId = object.interestId ?? '0';
    return message;
  },
};

function createBaseAddEmployeeInterestResponse(): AddEmployeeInterestResponse {
  return { status: 0, message: '' };
}

export const AddEmployeeInterestResponse = {
  encode(
    message: AddEmployeeInterestResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddEmployeeInterestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddEmployeeInterestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddEmployeeInterestResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: AddEmployeeInterestResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddEmployeeInterestResponse>, I>>(
    base?: I,
  ): AddEmployeeInterestResponse {
    return AddEmployeeInterestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddEmployeeInterestResponse>, I>>(
    object: I,
  ): AddEmployeeInterestResponse {
    const message = createBaseAddEmployeeInterestResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseRemoveEmployeeInterestRequest(): RemoveEmployeeInterestRequest {
  return { interestId: '0' };
}

export const RemoveEmployeeInterestRequest = {
  encode(
    message: RemoveEmployeeInterestRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.interestId !== '0') {
      writer.uint32(8).int64(message.interestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveEmployeeInterestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveEmployeeInterestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.interestId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveEmployeeInterestRequest {
    return { interestId: isSet(object.interestId) ? globalThis.String(object.interestId) : '0' };
  },

  toJSON(message: RemoveEmployeeInterestRequest): unknown {
    const obj: any = {};
    if (message.interestId !== '0') {
      obj.interestId = message.interestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveEmployeeInterestRequest>, I>>(
    base?: I,
  ): RemoveEmployeeInterestRequest {
    return RemoveEmployeeInterestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveEmployeeInterestRequest>, I>>(
    object: I,
  ): RemoveEmployeeInterestRequest {
    const message = createBaseRemoveEmployeeInterestRequest();
    message.interestId = object.interestId ?? '0';
    return message;
  },
};

function createBaseRemoveEmployeeInterestResponse(): RemoveEmployeeInterestResponse {
  return { status: 0, message: '' };
}

export const RemoveEmployeeInterestResponse = {
  encode(
    message: RemoveEmployeeInterestResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveEmployeeInterestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveEmployeeInterestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveEmployeeInterestResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: RemoveEmployeeInterestResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveEmployeeInterestResponse>, I>>(
    base?: I,
  ): RemoveEmployeeInterestResponse {
    return RemoveEmployeeInterestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveEmployeeInterestResponse>, I>>(
    object: I,
  ): RemoveEmployeeInterestResponse {
    const message = createBaseRemoveEmployeeInterestResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseGetBadgeWallDetailRequest(): GetBadgeWallDetailRequest {
  return { badgeId: '0' };
}

export const GetBadgeWallDetailRequest = {
  encode(message: GetBadgeWallDetailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBadgeWallDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBadgeWallDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBadgeWallDetailRequest {
    return { badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0' };
  },

  toJSON(message: GetBadgeWallDetailRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBadgeWallDetailRequest>, I>>(
    base?: I,
  ): GetBadgeWallDetailRequest {
    return GetBadgeWallDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBadgeWallDetailRequest>, I>>(
    object: I,
  ): GetBadgeWallDetailRequest {
    const message = createBaseGetBadgeWallDetailRequest();
    message.badgeId = object.badgeId ?? '0';
    return message;
  },
};

function createBaseGetBadgeWallDetailResponse(): GetBadgeWallDetailResponse {
  return { status: 0, message: '', badge: undefined, assignees: [], owner: undefined };
}

export const GetBadgeWallDetailResponse = {
  encode(
    message: GetBadgeWallDetailResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.badge !== undefined) {
      Badge.encode(message.badge, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.assignees) {
      Employee.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.owner !== undefined) {
      Employee.encode(message.owner, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBadgeWallDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBadgeWallDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.badge = Badge.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.assignees.push(Employee.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.owner = Employee.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBadgeWallDetailResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      badge: isSet(object.badge) ? Badge.fromJSON(object.badge) : undefined,
      assignees: globalThis.Array.isArray(object?.assignees)
        ? object.assignees.map((e: any) => Employee.fromJSON(e))
        : [],
      owner: isSet(object.owner) ? Employee.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: GetBadgeWallDetailResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.badge !== undefined) {
      obj.badge = Badge.toJSON(message.badge);
    }
    if (message.assignees?.length) {
      obj.assignees = message.assignees.map((e) => Employee.toJSON(e));
    }
    if (message.owner !== undefined) {
      obj.owner = Employee.toJSON(message.owner);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBadgeWallDetailResponse>, I>>(
    base?: I,
  ): GetBadgeWallDetailResponse {
    return GetBadgeWallDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBadgeWallDetailResponse>, I>>(
    object: I,
  ): GetBadgeWallDetailResponse {
    const message = createBaseGetBadgeWallDetailResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.badge =
      object.badge !== undefined && object.badge !== null
        ? Badge.fromPartial(object.badge)
        : undefined;
    message.assignees = object.assignees?.map((e) => Employee.fromPartial(e)) || [];
    message.owner =
      object.owner !== undefined && object.owner !== null
        ? Employee.fromPartial(object.owner)
        : undefined;
    return message;
  },
};

function createBaseGetAllBadgesRequest(): GetAllBadgesRequest {
  return {};
}

export const GetAllBadgesRequest = {
  encode(_: GetAllBadgesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllBadgesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllBadgesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAllBadgesRequest {
    return {};
  },

  toJSON(_: GetAllBadgesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllBadgesRequest>, I>>(base?: I): GetAllBadgesRequest {
    return GetAllBadgesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllBadgesRequest>, I>>(_: I): GetAllBadgesRequest {
    const message = createBaseGetAllBadgesRequest();
    return message;
  },
};

function createBaseGetAllBadgesResponse(): GetAllBadgesResponse {
  return { status: 0, message: '', badges: [] };
}

export const GetAllBadgesResponse = {
  encode(message: GetAllBadgesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.badges) {
      Badge.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllBadgesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllBadgesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.badges.push(Badge.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllBadgesResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      badges: globalThis.Array.isArray(object?.badges)
        ? object.badges.map((e: any) => Badge.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllBadgesResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.badges?.length) {
      obj.badges = message.badges.map((e) => Badge.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllBadgesResponse>, I>>(base?: I): GetAllBadgesResponse {
    return GetAllBadgesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllBadgesResponse>, I>>(
    object: I,
  ): GetAllBadgesResponse {
    const message = createBaseGetAllBadgesResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.badges = object.badges?.map((e) => Badge.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubmitNewBadgeRequest(): SubmitNewBadgeRequest {
  return { application: undefined };
}

export const SubmitNewBadgeRequest = {
  encode(message: SubmitNewBadgeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.application !== undefined) {
      BadgeApplication.encode(message.application, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitNewBadgeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitNewBadgeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.application = BadgeApplication.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitNewBadgeRequest {
    return {
      application: isSet(object.application)
        ? BadgeApplication.fromJSON(object.application)
        : undefined,
    };
  },

  toJSON(message: SubmitNewBadgeRequest): unknown {
    const obj: any = {};
    if (message.application !== undefined) {
      obj.application = BadgeApplication.toJSON(message.application);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitNewBadgeRequest>, I>>(base?: I): SubmitNewBadgeRequest {
    return SubmitNewBadgeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitNewBadgeRequest>, I>>(
    object: I,
  ): SubmitNewBadgeRequest {
    const message = createBaseSubmitNewBadgeRequest();
    message.application =
      object.application !== undefined && object.application !== null
        ? BadgeApplication.fromPartial(object.application)
        : undefined;
    return message;
  },
};

function createBaseSubmitNewBadgeResponse(): SubmitNewBadgeResponse {
  return { status: 0, message: '' };
}

export const SubmitNewBadgeResponse = {
  encode(message: SubmitNewBadgeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitNewBadgeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitNewBadgeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitNewBadgeResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: SubmitNewBadgeResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitNewBadgeResponse>, I>>(
    base?: I,
  ): SubmitNewBadgeResponse {
    return SubmitNewBadgeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitNewBadgeResponse>, I>>(
    object: I,
  ): SubmitNewBadgeResponse {
    const message = createBaseSubmitNewBadgeResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseUpdateBadgeDetailRequest(): UpdateBadgeDetailRequest {
  return { badgeId: '0', name: '', description: '', iconImage: new Uint8Array(0) };
}

export const UpdateBadgeDetailRequest = {
  encode(message: UpdateBadgeDetailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.iconImage.length !== 0) {
      writer.uint32(34).bytes(message.iconImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBadgeDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBadgeDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconImage = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBadgeDetailRequest {
    return {
      badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0',
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      description: isSet(object.description) ? globalThis.String(object.description) : '',
      iconImage: isSet(object.iconImage) ? bytesFromBase64(object.iconImage) : new Uint8Array(0),
    };
  },

  toJSON(message: UpdateBadgeDetailRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.description !== '') {
      obj.description = message.description;
    }
    if (message.iconImage.length !== 0) {
      obj.iconImage = base64FromBytes(message.iconImage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBadgeDetailRequest>, I>>(
    base?: I,
  ): UpdateBadgeDetailRequest {
    return UpdateBadgeDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBadgeDetailRequest>, I>>(
    object: I,
  ): UpdateBadgeDetailRequest {
    const message = createBaseUpdateBadgeDetailRequest();
    message.badgeId = object.badgeId ?? '0';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.iconImage = object.iconImage ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUpdateBadgeDetailResponse(): UpdateBadgeDetailResponse {
  return { status: 0, message: '', badge: undefined };
}

export const UpdateBadgeDetailResponse = {
  encode(message: UpdateBadgeDetailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.badge !== undefined) {
      Badge.encode(message.badge, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBadgeDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBadgeDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.badge = Badge.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBadgeDetailResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      badge: isSet(object.badge) ? Badge.fromJSON(object.badge) : undefined,
    };
  },

  toJSON(message: UpdateBadgeDetailResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.badge !== undefined) {
      obj.badge = Badge.toJSON(message.badge);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBadgeDetailResponse>, I>>(
    base?: I,
  ): UpdateBadgeDetailResponse {
    return UpdateBadgeDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBadgeDetailResponse>, I>>(
    object: I,
  ): UpdateBadgeDetailResponse {
    const message = createBaseUpdateBadgeDetailResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.badge =
      object.badge !== undefined && object.badge !== null
        ? Badge.fromPartial(object.badge)
        : undefined;
    return message;
  },
};

function createBaseGetPendingBadgesRequest(): GetPendingBadgesRequest {
  return {};
}

export const GetPendingBadgesRequest = {
  encode(_: GetPendingBadgesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPendingBadgesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPendingBadgesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetPendingBadgesRequest {
    return {};
  },

  toJSON(_: GetPendingBadgesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPendingBadgesRequest>, I>>(
    base?: I,
  ): GetPendingBadgesRequest {
    return GetPendingBadgesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPendingBadgesRequest>, I>>(
    _: I,
  ): GetPendingBadgesRequest {
    const message = createBaseGetPendingBadgesRequest();
    return message;
  },
};

function createBaseGetPendingBadgesResponse(): GetPendingBadgesResponse {
  return { status: 0, message: '', badges: [] };
}

export const GetPendingBadgesResponse = {
  encode(message: GetPendingBadgesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.badges) {
      PendingBadge.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPendingBadgesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPendingBadgesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.badges.push(PendingBadge.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPendingBadgesResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      badges: globalThis.Array.isArray(object?.badges)
        ? object.badges.map((e: any) => PendingBadge.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetPendingBadgesResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.badges?.length) {
      obj.badges = message.badges.map((e) => PendingBadge.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPendingBadgesResponse>, I>>(
    base?: I,
  ): GetPendingBadgesResponse {
    return GetPendingBadgesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPendingBadgesResponse>, I>>(
    object: I,
  ): GetPendingBadgesResponse {
    const message = createBaseGetPendingBadgesResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.badges = object.badges?.map((e) => PendingBadge.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubmitBadgeDecisionRequest(): SubmitBadgeDecisionRequest {
  return { badgeId: '0', decision: 0, reason: '' };
}

export const SubmitBadgeDecisionRequest = {
  encode(
    message: SubmitBadgeDecisionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    if (message.decision !== 0) {
      writer.uint32(16).int32(message.decision);
    }
    if (message.reason !== '') {
      writer.uint32(26).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitBadgeDecisionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitBadgeDecisionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.decision = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitBadgeDecisionRequest {
    return {
      badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0',
      decision: isSet(object.decision)
        ? submitBadgeDecisionRequest_BadgeDecisionTypeFromJSON(object.decision)
        : 0,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : '',
    };
  },

  toJSON(message: SubmitBadgeDecisionRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    if (message.decision !== 0) {
      obj.decision = submitBadgeDecisionRequest_BadgeDecisionTypeToJSON(message.decision);
    }
    if (message.reason !== '') {
      obj.reason = message.reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitBadgeDecisionRequest>, I>>(
    base?: I,
  ): SubmitBadgeDecisionRequest {
    return SubmitBadgeDecisionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitBadgeDecisionRequest>, I>>(
    object: I,
  ): SubmitBadgeDecisionRequest {
    const message = createBaseSubmitBadgeDecisionRequest();
    message.badgeId = object.badgeId ?? '0';
    message.decision = object.decision ?? 0;
    message.reason = object.reason ?? '';
    return message;
  },
};

function createBaseSubmitBadgeDecisionResponse(): SubmitBadgeDecisionResponse {
  return { status: 0, message: '' };
}

export const SubmitBadgeDecisionResponse = {
  encode(
    message: SubmitBadgeDecisionResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitBadgeDecisionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitBadgeDecisionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitBadgeDecisionResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: SubmitBadgeDecisionResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitBadgeDecisionResponse>, I>>(
    base?: I,
  ): SubmitBadgeDecisionResponse {
    return SubmitBadgeDecisionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitBadgeDecisionResponse>, I>>(
    object: I,
  ): SubmitBadgeDecisionResponse {
    const message = createBaseSubmitBadgeDecisionResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseAddBadgeAssigneeRequest(): AddBadgeAssigneeRequest {
  return { badgeId: '0', employeeId: '0' };
}

export const AddBadgeAssigneeRequest = {
  encode(message: AddBadgeAssigneeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    if (message.employeeId !== '0') {
      writer.uint32(16).int64(message.employeeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddBadgeAssigneeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBadgeAssigneeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.employeeId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddBadgeAssigneeRequest {
    return {
      badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0',
      employeeId: isSet(object.employeeId) ? globalThis.String(object.employeeId) : '0',
    };
  },

  toJSON(message: AddBadgeAssigneeRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    if (message.employeeId !== '0') {
      obj.employeeId = message.employeeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddBadgeAssigneeRequest>, I>>(
    base?: I,
  ): AddBadgeAssigneeRequest {
    return AddBadgeAssigneeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBadgeAssigneeRequest>, I>>(
    object: I,
  ): AddBadgeAssigneeRequest {
    const message = createBaseAddBadgeAssigneeRequest();
    message.badgeId = object.badgeId ?? '0';
    message.employeeId = object.employeeId ?? '0';
    return message;
  },
};

function createBaseAddBadgeAssigneeResponse(): AddBadgeAssigneeResponse {
  return { status: 0, message: '' };
}

export const AddBadgeAssigneeResponse = {
  encode(message: AddBadgeAssigneeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddBadgeAssigneeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBadgeAssigneeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddBadgeAssigneeResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: AddBadgeAssigneeResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddBadgeAssigneeResponse>, I>>(
    base?: I,
  ): AddBadgeAssigneeResponse {
    return AddBadgeAssigneeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBadgeAssigneeResponse>, I>>(
    object: I,
  ): AddBadgeAssigneeResponse {
    const message = createBaseAddBadgeAssigneeResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseRemoveBadgeAssigneeRequest(): RemoveBadgeAssigneeRequest {
  return { badgeId: '0', employeeId: '0' };
}

export const RemoveBadgeAssigneeRequest = {
  encode(
    message: RemoveBadgeAssigneeRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    if (message.employeeId !== '0') {
      writer.uint32(16).int64(message.employeeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveBadgeAssigneeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBadgeAssigneeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.employeeId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveBadgeAssigneeRequest {
    return {
      badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0',
      employeeId: isSet(object.employeeId) ? globalThis.String(object.employeeId) : '0',
    };
  },

  toJSON(message: RemoveBadgeAssigneeRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    if (message.employeeId !== '0') {
      obj.employeeId = message.employeeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveBadgeAssigneeRequest>, I>>(
    base?: I,
  ): RemoveBadgeAssigneeRequest {
    return RemoveBadgeAssigneeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveBadgeAssigneeRequest>, I>>(
    object: I,
  ): RemoveBadgeAssigneeRequest {
    const message = createBaseRemoveBadgeAssigneeRequest();
    message.badgeId = object.badgeId ?? '0';
    message.employeeId = object.employeeId ?? '0';
    return message;
  },
};

function createBaseRemoveBadgeAssigneeResponse(): RemoveBadgeAssigneeResponse {
  return { status: 0, message: '' };
}

export const RemoveBadgeAssigneeResponse = {
  encode(
    message: RemoveBadgeAssigneeResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveBadgeAssigneeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBadgeAssigneeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveBadgeAssigneeResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: RemoveBadgeAssigneeResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveBadgeAssigneeResponse>, I>>(
    base?: I,
  ): RemoveBadgeAssigneeResponse {
    return RemoveBadgeAssigneeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveBadgeAssigneeResponse>, I>>(
    object: I,
  ): RemoveBadgeAssigneeResponse {
    const message = createBaseRemoveBadgeAssigneeResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseAddEmployeeBadgeRequest(): AddEmployeeBadgeRequest {
  return { badgeId: '0' };
}

export const AddEmployeeBadgeRequest = {
  encode(message: AddEmployeeBadgeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badgeId !== '0') {
      writer.uint32(8).int64(message.badgeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddEmployeeBadgeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddEmployeeBadgeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.badgeId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddEmployeeBadgeRequest {
    return { badgeId: isSet(object.badgeId) ? globalThis.String(object.badgeId) : '0' };
  },

  toJSON(message: AddEmployeeBadgeRequest): unknown {
    const obj: any = {};
    if (message.badgeId !== '0') {
      obj.badgeId = message.badgeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddEmployeeBadgeRequest>, I>>(
    base?: I,
  ): AddEmployeeBadgeRequest {
    return AddEmployeeBadgeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddEmployeeBadgeRequest>, I>>(
    object: I,
  ): AddEmployeeBadgeRequest {
    const message = createBaseAddEmployeeBadgeRequest();
    message.badgeId = object.badgeId ?? '0';
    return message;
  },
};

function createBaseAddEmployeeBadgeResponse(): AddEmployeeBadgeResponse {
  return { status: 0, message: '' };
}

export const AddEmployeeBadgeResponse = {
  encode(message: AddEmployeeBadgeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddEmployeeBadgeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddEmployeeBadgeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddEmployeeBadgeResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
    };
  },

  toJSON(message: AddEmployeeBadgeResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddEmployeeBadgeResponse>, I>>(
    base?: I,
  ): AddEmployeeBadgeResponse {
    return AddEmployeeBadgeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddEmployeeBadgeResponse>, I>>(
    object: I,
  ): AddEmployeeBadgeResponse {
    const message = createBaseAddEmployeeBadgeResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    return message;
  },
};

function createBaseGetDirectReportsRequest(): GetDirectReportsRequest {
  return { employeeId: '0' };
}

export const GetDirectReportsRequest = {
  encode(message: GetDirectReportsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.employeeId !== '0') {
      writer.uint32(8).int64(message.employeeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDirectReportsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDirectReportsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.employeeId = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDirectReportsRequest {
    return { employeeId: isSet(object.employeeId) ? globalThis.String(object.employeeId) : '0' };
  },

  toJSON(message: GetDirectReportsRequest): unknown {
    const obj: any = {};
    if (message.employeeId !== '0') {
      obj.employeeId = message.employeeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDirectReportsRequest>, I>>(
    base?: I,
  ): GetDirectReportsRequest {
    return GetDirectReportsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDirectReportsRequest>, I>>(
    object: I,
  ): GetDirectReportsRequest {
    const message = createBaseGetDirectReportsRequest();
    message.employeeId = object.employeeId ?? '0';
    return message;
  },
};

function createBaseGetDirectReportsResponse(): GetDirectReportsResponse {
  return { status: 0, message: '', reports: [] };
}

export const GetDirectReportsResponse = {
  encode(message: GetDirectReportsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.reports) {
      Employee.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDirectReportsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDirectReportsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reports.push(Employee.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDirectReportsResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      reports: globalThis.Array.isArray(object?.reports)
        ? object.reports.map((e: any) => Employee.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetDirectReportsResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.reports?.length) {
      obj.reports = message.reports.map((e) => Employee.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDirectReportsResponse>, I>>(
    base?: I,
  ): GetDirectReportsResponse {
    return GetDirectReportsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDirectReportsResponse>, I>>(
    object: I,
  ): GetDirectReportsResponse {
    const message = createBaseGetDirectReportsResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.reports = object.reports?.map((e) => Employee.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchEmployeesRequest(): SearchEmployeesRequest {
  return { keyword: '' };
}

export const SearchEmployeesRequest = {
  encode(message: SearchEmployeesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyword !== '') {
      writer.uint32(10).string(message.keyword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchEmployeesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchEmployeesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchEmployeesRequest {
    return { keyword: isSet(object.keyword) ? globalThis.String(object.keyword) : '' };
  },

  toJSON(message: SearchEmployeesRequest): unknown {
    const obj: any = {};
    if (message.keyword !== '') {
      obj.keyword = message.keyword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchEmployeesRequest>, I>>(
    base?: I,
  ): SearchEmployeesRequest {
    return SearchEmployeesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchEmployeesRequest>, I>>(
    object: I,
  ): SearchEmployeesRequest {
    const message = createBaseSearchEmployeesRequest();
    message.keyword = object.keyword ?? '';
    return message;
  },
};

function createBaseSearchEmployeesResponse(): SearchEmployeesResponse {
  return { status: 0, message: '', employees: [] };
}

export const SearchEmployeesResponse = {
  encode(message: SearchEmployeesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.employees) {
      EmployeeFound.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchEmployeesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchEmployeesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.employees.push(EmployeeFound.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchEmployeesResponse {
    return {
      status: isSet(object.status) ? statusTypeFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : '',
      employees: globalThis.Array.isArray(object?.employees)
        ? object.employees.map((e: any) => EmployeeFound.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SearchEmployeesResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = statusTypeToJSON(message.status);
    }
    if (message.message !== '') {
      obj.message = message.message;
    }
    if (message.employees?.length) {
      obj.employees = message.employees.map((e) => EmployeeFound.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchEmployeesResponse>, I>>(
    base?: I,
  ): SearchEmployeesResponse {
    return SearchEmployeesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchEmployeesResponse>, I>>(
    object: I,
  ): SearchEmployeesResponse {
    const message = createBaseSearchEmployeesResponse();
    message.status = object.status ?? 0;
    message.message = object.message ?? '';
    message.employees = object.employees?.map((e) => EmployeeFound.fromPartial(e)) || [];
    return message;
  },
};

export interface ZallWeb {
  /** zallwall user */
  GetMyInfo(request: GetMyInfoRequest): Promise<GetMyInfoResponse>;
  GetMyBadges(request: GetMyBadgesRequest): Promise<GetMyBadgesResponse>;
  GetMyInterests(request: GetMyInterestsRequest): Promise<GetMyInterestsResponse>;
  GetMyImpersonatees(request: GetMyImpersonateesRequest): Promise<GetMyImpersonateesResponse>;
  /** zallwall data */
  GetWallConfig(request: GetWallConfigRequest): Promise<GetWallConfigResponse>;
  GetWallDetail(request: GetWallDetailRequest): Promise<GetWallDetailResponse>;
  /** personal info */
  UpdateEmployeePronoun(
    request: UpdateEmployeePronounRequest,
  ): Promise<UpdateEmployeePronounResponse>;
  UpdateEmployeeFavoriteCoreValue(
    request: UpdateEmployeeFavoriteCoreValueRequest,
  ): Promise<UpdateEmployeeFavoriteCoreValueResponse>;
  UpdateEmployeeFunFact(
    request: UpdateEmployeeFunFactRequest,
  ): Promise<UpdateEmployeeFunFactResponse>;
  UpdateEmployeePrimaryContactNumber(
    request: UpdateEmployeePrimaryContactNumberRequest,
  ): Promise<UpdateEmployeePrimaryContactNumberResponse>;
  UpdateEmployeeNamePronunciation(
    request: UpdateEmployeeNamePronunciationRequest,
  ): Promise<UpdateEmployeeNamePronunciationResponse>;
  UpdateEmployeeLinkedInProfileUrl(
    request: UpdateEmployeeLinkedInProfileUrlRequest,
  ): Promise<UpdateEmployeeLinkedInProfileUrlResponse>;
  /** karma */
  GetEmployeeKarmas(request: GetEmployeeKarmasRequest): Promise<GetEmployeeKarmasResponse>;
  DeleteEmployeeKarma(request: DeleteEmployeeKarmaRequest): Promise<DeleteEmployeeKarmaResponse>;
  GiveKarma(request: GiveKarmaRequest): Promise<GiveKarmaResponse>;
  /** insights */
  GetInsightsTwins(request: GetInsightsTwinsRequest): Promise<GetInsightsTwinsResponse>;
  GetEmployeesWithInsightsColor(
    request: GetEmployeesWithInsightsColorRequest,
  ): Promise<GetEmployeesWithInsightsColorResponse>;
  UpdateEmployeeInsights(
    request: UpdateEmployeeInsightsRequest,
  ): Promise<UpdateEmployeeInsightsResponse>;
  /** interest */
  GetInterestWallDetail(
    request: GetInterestWallDetailRequest,
  ): Promise<GetInterestWallDetailResponse>;
  GetAllInterests(request: GetAllInterestsRequest): Promise<GetAllInterestsResponse>;
  CreateInterest(request: CreateInterestRequest): Promise<CreateInterestResponse>;
  AddEmployeeInterest(request: AddEmployeeInterestRequest): Promise<AddEmployeeInterestResponse>;
  RemoveEmployeeInterest(
    request: RemoveEmployeeInterestRequest,
  ): Promise<RemoveEmployeeInterestResponse>;
  /** badge */
  GetBadgeWallDetail(request: GetBadgeWallDetailRequest): Promise<GetBadgeWallDetailResponse>;
  GetAllBadges(request: GetAllBadgesRequest): Promise<GetAllBadgesResponse>;
  SubmitNewBadge(request: SubmitNewBadgeRequest): Promise<SubmitNewBadgeResponse>;
  UpdateBadgeDetail(request: UpdateBadgeDetailRequest): Promise<UpdateBadgeDetailResponse>;
  GetPendingBadges(request: GetPendingBadgesRequest): Promise<GetPendingBadgesResponse>;
  SubmitBadgeDecision(request: SubmitBadgeDecisionRequest): Promise<SubmitBadgeDecisionResponse>;
  AddBadgeAssignee(request: AddBadgeAssigneeRequest): Promise<AddBadgeAssigneeResponse>;
  RemoveBadgeAssignee(request: RemoveBadgeAssigneeRequest): Promise<RemoveBadgeAssigneeResponse>;
  AddEmployeeBadge(request: AddEmployeeBadgeRequest): Promise<AddEmployeeBadgeResponse>;
  /** team */
  GetDirectReports(request: GetDirectReportsRequest): Promise<GetDirectReportsResponse>;
  /** search */
  SearchEmployees(request: SearchEmployeesRequest): Promise<SearchEmployeesResponse>;
}

export const ZallWebServiceName = 'zallengine_contract.ZallWeb';
export class ZallWebClientImpl implements ZallWeb {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ZallWebServiceName;
    this.rpc = rpc;
    this.GetMyInfo = this.GetMyInfo.bind(this);
    this.GetMyBadges = this.GetMyBadges.bind(this);
    this.GetMyInterests = this.GetMyInterests.bind(this);
    this.GetMyImpersonatees = this.GetMyImpersonatees.bind(this);
    this.GetWallConfig = this.GetWallConfig.bind(this);
    this.GetWallDetail = this.GetWallDetail.bind(this);
    this.UpdateEmployeePronoun = this.UpdateEmployeePronoun.bind(this);
    this.UpdateEmployeeFavoriteCoreValue = this.UpdateEmployeeFavoriteCoreValue.bind(this);
    this.UpdateEmployeeFunFact = this.UpdateEmployeeFunFact.bind(this);
    this.UpdateEmployeePrimaryContactNumber = this.UpdateEmployeePrimaryContactNumber.bind(this);
    this.UpdateEmployeeNamePronunciation = this.UpdateEmployeeNamePronunciation.bind(this);
    this.UpdateEmployeeLinkedInProfileUrl = this.UpdateEmployeeLinkedInProfileUrl.bind(this);
    this.GetEmployeeKarmas = this.GetEmployeeKarmas.bind(this);
    this.DeleteEmployeeKarma = this.DeleteEmployeeKarma.bind(this);
    this.GiveKarma = this.GiveKarma.bind(this);
    this.GetInsightsTwins = this.GetInsightsTwins.bind(this);
    this.GetEmployeesWithInsightsColor = this.GetEmployeesWithInsightsColor.bind(this);
    this.UpdateEmployeeInsights = this.UpdateEmployeeInsights.bind(this);
    this.GetInterestWallDetail = this.GetInterestWallDetail.bind(this);
    this.GetAllInterests = this.GetAllInterests.bind(this);
    this.CreateInterest = this.CreateInterest.bind(this);
    this.AddEmployeeInterest = this.AddEmployeeInterest.bind(this);
    this.RemoveEmployeeInterest = this.RemoveEmployeeInterest.bind(this);
    this.GetBadgeWallDetail = this.GetBadgeWallDetail.bind(this);
    this.GetAllBadges = this.GetAllBadges.bind(this);
    this.SubmitNewBadge = this.SubmitNewBadge.bind(this);
    this.UpdateBadgeDetail = this.UpdateBadgeDetail.bind(this);
    this.GetPendingBadges = this.GetPendingBadges.bind(this);
    this.SubmitBadgeDecision = this.SubmitBadgeDecision.bind(this);
    this.AddBadgeAssignee = this.AddBadgeAssignee.bind(this);
    this.RemoveBadgeAssignee = this.RemoveBadgeAssignee.bind(this);
    this.AddEmployeeBadge = this.AddEmployeeBadge.bind(this);
    this.GetDirectReports = this.GetDirectReports.bind(this);
    this.SearchEmployees = this.SearchEmployees.bind(this);
  }
  GetMyInfo(request: GetMyInfoRequest): Promise<GetMyInfoResponse> {
    const data = GetMyInfoRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetMyInfo', data);
    return promise.then((data) => GetMyInfoResponse.decode(_m0.Reader.create(data)));
  }

  GetMyBadges(request: GetMyBadgesRequest): Promise<GetMyBadgesResponse> {
    const data = GetMyBadgesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetMyBadges', data);
    return promise.then((data) => GetMyBadgesResponse.decode(_m0.Reader.create(data)));
  }

  GetMyInterests(request: GetMyInterestsRequest): Promise<GetMyInterestsResponse> {
    const data = GetMyInterestsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetMyInterests', data);
    return promise.then((data) => GetMyInterestsResponse.decode(_m0.Reader.create(data)));
  }

  GetMyImpersonatees(request: GetMyImpersonateesRequest): Promise<GetMyImpersonateesResponse> {
    const data = GetMyImpersonateesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetMyImpersonatees', data);
    return promise.then((data) => GetMyImpersonateesResponse.decode(_m0.Reader.create(data)));
  }

  GetWallConfig(request: GetWallConfigRequest): Promise<GetWallConfigResponse> {
    const data = GetWallConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetWallConfig', data);
    return promise.then((data) => GetWallConfigResponse.decode(_m0.Reader.create(data)));
  }

  GetWallDetail(request: GetWallDetailRequest): Promise<GetWallDetailResponse> {
    const data = GetWallDetailRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetWallDetail', data);
    return promise.then((data) => GetWallDetailResponse.decode(_m0.Reader.create(data)));
  }

  UpdateEmployeePronoun(
    request: UpdateEmployeePronounRequest,
  ): Promise<UpdateEmployeePronounResponse> {
    const data = UpdateEmployeePronounRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeePronoun', data);
    return promise.then((data) => UpdateEmployeePronounResponse.decode(_m0.Reader.create(data)));
  }

  UpdateEmployeeFavoriteCoreValue(
    request: UpdateEmployeeFavoriteCoreValueRequest,
  ): Promise<UpdateEmployeeFavoriteCoreValueResponse> {
    const data = UpdateEmployeeFavoriteCoreValueRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeeFavoriteCoreValue', data);
    return promise.then((data) =>
      UpdateEmployeeFavoriteCoreValueResponse.decode(_m0.Reader.create(data)),
    );
  }

  UpdateEmployeeFunFact(
    request: UpdateEmployeeFunFactRequest,
  ): Promise<UpdateEmployeeFunFactResponse> {
    const data = UpdateEmployeeFunFactRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeeFunFact', data);
    return promise.then((data) => UpdateEmployeeFunFactResponse.decode(_m0.Reader.create(data)));
  }

  UpdateEmployeePrimaryContactNumber(
    request: UpdateEmployeePrimaryContactNumberRequest,
  ): Promise<UpdateEmployeePrimaryContactNumberResponse> {
    const data = UpdateEmployeePrimaryContactNumberRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeePrimaryContactNumber', data);
    return promise.then((data) =>
      UpdateEmployeePrimaryContactNumberResponse.decode(_m0.Reader.create(data)),
    );
  }

  UpdateEmployeeNamePronunciation(
    request: UpdateEmployeeNamePronunciationRequest,
  ): Promise<UpdateEmployeeNamePronunciationResponse> {
    const data = UpdateEmployeeNamePronunciationRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeeNamePronunciation', data);
    return promise.then((data) =>
      UpdateEmployeeNamePronunciationResponse.decode(_m0.Reader.create(data)),
    );
  }

  UpdateEmployeeLinkedInProfileUrl(
    request: UpdateEmployeeLinkedInProfileUrlRequest,
  ): Promise<UpdateEmployeeLinkedInProfileUrlResponse> {
    const data = UpdateEmployeeLinkedInProfileUrlRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeeLinkedInProfileUrl', data);
    return promise.then((data) =>
      UpdateEmployeeLinkedInProfileUrlResponse.decode(_m0.Reader.create(data)),
    );
  }

  GetEmployeeKarmas(request: GetEmployeeKarmasRequest): Promise<GetEmployeeKarmasResponse> {
    const data = GetEmployeeKarmasRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetEmployeeKarmas', data);
    return promise.then((data) => GetEmployeeKarmasResponse.decode(_m0.Reader.create(data)));
  }

  DeleteEmployeeKarma(request: DeleteEmployeeKarmaRequest): Promise<DeleteEmployeeKarmaResponse> {
    const data = DeleteEmployeeKarmaRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'DeleteEmployeeKarma', data);
    return promise.then((data) => DeleteEmployeeKarmaResponse.decode(_m0.Reader.create(data)));
  }

  GiveKarma(request: GiveKarmaRequest): Promise<GiveKarmaResponse> {
    const data = GiveKarmaRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GiveKarma', data);
    return promise.then((data) => GiveKarmaResponse.decode(_m0.Reader.create(data)));
  }

  GetInsightsTwins(request: GetInsightsTwinsRequest): Promise<GetInsightsTwinsResponse> {
    const data = GetInsightsTwinsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetInsightsTwins', data);
    return promise.then((data) => GetInsightsTwinsResponse.decode(_m0.Reader.create(data)));
  }

  GetEmployeesWithInsightsColor(
    request: GetEmployeesWithInsightsColorRequest,
  ): Promise<GetEmployeesWithInsightsColorResponse> {
    const data = GetEmployeesWithInsightsColorRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetEmployeesWithInsightsColor', data);
    return promise.then((data) =>
      GetEmployeesWithInsightsColorResponse.decode(_m0.Reader.create(data)),
    );
  }

  UpdateEmployeeInsights(
    request: UpdateEmployeeInsightsRequest,
  ): Promise<UpdateEmployeeInsightsResponse> {
    const data = UpdateEmployeeInsightsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateEmployeeInsights', data);
    return promise.then((data) => UpdateEmployeeInsightsResponse.decode(_m0.Reader.create(data)));
  }

  GetInterestWallDetail(
    request: GetInterestWallDetailRequest,
  ): Promise<GetInterestWallDetailResponse> {
    const data = GetInterestWallDetailRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetInterestWallDetail', data);
    return promise.then((data) => GetInterestWallDetailResponse.decode(_m0.Reader.create(data)));
  }

  GetAllInterests(request: GetAllInterestsRequest): Promise<GetAllInterestsResponse> {
    const data = GetAllInterestsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetAllInterests', data);
    return promise.then((data) => GetAllInterestsResponse.decode(_m0.Reader.create(data)));
  }

  CreateInterest(request: CreateInterestRequest): Promise<CreateInterestResponse> {
    const data = CreateInterestRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'CreateInterest', data);
    return promise.then((data) => CreateInterestResponse.decode(_m0.Reader.create(data)));
  }

  AddEmployeeInterest(request: AddEmployeeInterestRequest): Promise<AddEmployeeInterestResponse> {
    const data = AddEmployeeInterestRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'AddEmployeeInterest', data);
    return promise.then((data) => AddEmployeeInterestResponse.decode(_m0.Reader.create(data)));
  }

  RemoveEmployeeInterest(
    request: RemoveEmployeeInterestRequest,
  ): Promise<RemoveEmployeeInterestResponse> {
    const data = RemoveEmployeeInterestRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'RemoveEmployeeInterest', data);
    return promise.then((data) => RemoveEmployeeInterestResponse.decode(_m0.Reader.create(data)));
  }

  GetBadgeWallDetail(request: GetBadgeWallDetailRequest): Promise<GetBadgeWallDetailResponse> {
    const data = GetBadgeWallDetailRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetBadgeWallDetail', data);
    return promise.then((data) => GetBadgeWallDetailResponse.decode(_m0.Reader.create(data)));
  }

  GetAllBadges(request: GetAllBadgesRequest): Promise<GetAllBadgesResponse> {
    const data = GetAllBadgesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetAllBadges', data);
    return promise.then((data) => GetAllBadgesResponse.decode(_m0.Reader.create(data)));
  }

  SubmitNewBadge(request: SubmitNewBadgeRequest): Promise<SubmitNewBadgeResponse> {
    const data = SubmitNewBadgeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'SubmitNewBadge', data);
    return promise.then((data) => SubmitNewBadgeResponse.decode(_m0.Reader.create(data)));
  }

  UpdateBadgeDetail(request: UpdateBadgeDetailRequest): Promise<UpdateBadgeDetailResponse> {
    const data = UpdateBadgeDetailRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'UpdateBadgeDetail', data);
    return promise.then((data) => UpdateBadgeDetailResponse.decode(_m0.Reader.create(data)));
  }

  GetPendingBadges(request: GetPendingBadgesRequest): Promise<GetPendingBadgesResponse> {
    const data = GetPendingBadgesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetPendingBadges', data);
    return promise.then((data) => GetPendingBadgesResponse.decode(_m0.Reader.create(data)));
  }

  SubmitBadgeDecision(request: SubmitBadgeDecisionRequest): Promise<SubmitBadgeDecisionResponse> {
    const data = SubmitBadgeDecisionRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'SubmitBadgeDecision', data);
    return promise.then((data) => SubmitBadgeDecisionResponse.decode(_m0.Reader.create(data)));
  }

  AddBadgeAssignee(request: AddBadgeAssigneeRequest): Promise<AddBadgeAssigneeResponse> {
    const data = AddBadgeAssigneeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'AddBadgeAssignee', data);
    return promise.then((data) => AddBadgeAssigneeResponse.decode(_m0.Reader.create(data)));
  }

  RemoveBadgeAssignee(request: RemoveBadgeAssigneeRequest): Promise<RemoveBadgeAssigneeResponse> {
    const data = RemoveBadgeAssigneeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'RemoveBadgeAssignee', data);
    return promise.then((data) => RemoveBadgeAssigneeResponse.decode(_m0.Reader.create(data)));
  }

  AddEmployeeBadge(request: AddEmployeeBadgeRequest): Promise<AddEmployeeBadgeResponse> {
    const data = AddEmployeeBadgeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'AddEmployeeBadge', data);
    return promise.then((data) => AddEmployeeBadgeResponse.decode(_m0.Reader.create(data)));
  }

  GetDirectReports(request: GetDirectReportsRequest): Promise<GetDirectReportsResponse> {
    const data = GetDirectReportsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'GetDirectReports', data);
    return promise.then((data) => GetDirectReportsResponse.decode(_m0.Reader.create(data)));
  }

  SearchEmployees(request: SearchEmployeesRequest): Promise<SearchEmployeesResponse> {
    const data = SearchEmployeesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, 'SearchEmployees', data);
    return promise.then((data) => SearchEmployeesResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, 'base64'));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString('base64');
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(''));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
