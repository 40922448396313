import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { RpcImpl } from '../../contract/rpc';
import {
  GetMyInfoRequest,
  GetMyInfoResponse,
  GetMyBadgesRequest,
  GetMyBadgesResponse,
  AddEmployeeBadgeRequest,
  AddEmployeeBadgeResponse,
  GetMyInterestsRequest,
  GetMyInterestsResponse,
  AddEmployeeInterestRequest,
  AddEmployeeInterestResponse,
  RemoveEmployeeInterestRequest,
  RemoveEmployeeInterestResponse,
  ZallWebClientImpl,
} from '../../contract/zallengine_zallweb';
import {
  UserInfo,
  AuthenticateUserAction,
  AuthenticateUserSuccess,
  AuthenticateUserError,
  LoadUserSuccess,
  LoadUserError,
  LoadMockUser,
  LogoutUserSuccess,
  ResetUser,
  UserState,
  LoadUserAction,
  LoadUserBadgesAction,
  LoadUserInterestsAction,
  LoadMyBadgesSuccess,
  LoadMyBadgesError,
  AddToMyBadgesSuccess,
  LoadMyInterestsSuccess,
  LoadMyInterestsError,
  EditMyInterestAction,
  AddToMyInterestsSuccess,
  RemoveFromMyInterestsSuccess,
} from './index';
import { Badge, Interest } from '../index';
import {
  validateAuthResp,
  validateGetMyInfoResp,
  validateGetMyBadgesResp,
  validateAddEmployeeBadgeResp,
  validateGetMyInterestsResp,
  validateAddEmployeeInterestResp,
  validateRemoveEmployeeInterestResp,
} from './validators';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { GetDisplayError } from '../errorHelpers';
import DynamicConfig from '../../config/DynamicConfig';
import { StartImpersonationMode, startImpersonationMode } from '../impersonation';

/**
 *
export type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;
 */

export const authenticateUserSuccess = (): AuthenticateUserSuccess => ({
  type: 'AUTHENTICATE_USER_SUCCESS',
});

export const authenticateUserError = (msg: string): AuthenticateUserError => ({
  type: 'AUTHENTICATE_USER_ERROR',
  msg,
});

export const authenticateUser = (
  authCode: string,
  redirectUrl: string,
): ThunkAction<Promise<void>, UserState, null | undefined, AuthenticateUserAction> => async (
  dispatch: ThunkDispatch<UserState, null | undefined, AuthenticateUserAction>,
): Promise<void> => {
  const headers = {
    withCredentials: true,
  };
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const url = `${ZALL_ENGINE_PROXY}/api/auth?auth_code=${authCode}&redirect_uri=${redirectUrl}`;
  return axios
    .get(url, headers)
    .then((resp) => {
      const err = validateAuthResp(resp.data);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(authenticateUserSuccess());
        return Promise.resolve();
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const error = GetDisplayError(err);
      return Promise.reject(error);
    });
};

export const loadUserSuccess = (userInfo: UserInfo): LoadUserSuccess => ({
  type: 'LOAD_USER_SUCCESS',
  userInfo: userInfo,
});

export const loadUserError = (msg: string): LoadUserError => ({
  type: 'LOAD_USER_ERROR',
  msg,
});

export const loadMockUser = (): LoadMockUser => ({
  type: 'LOAD_MOCK_USER',
});

export const loadUserByCookie = (): ThunkAction<
  Promise<UserInfo>,
  UserState,
  null,
  LoadUserAction
> => async (
  dispatch: ThunkDispatch<UserState, null, LoadUserAction | StartImpersonationMode>,
): Promise<UserInfo> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  return client
    .GetMyInfo({} as GetMyInfoRequest)
    .then((resp: GetMyInfoResponse) => {
      const [user, impersonator, err] = validateGetMyInfoResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadUserSuccess(user));

        if (impersonator !== undefined) {
          dispatch(startImpersonationMode(impersonator));
        }
        return Promise.resolve(user);
      }
      return Promise.reject(err);
    })
    .catch((err) => {
      const error = GetDisplayError(err);
      dispatch(loadUserError(`ErrorType: ${error.code}`));
      return Promise.reject(error);
    });
};

export const logoutUserSuccess = (): LogoutUserSuccess => ({
  type: 'LOGOUT_USER_SUCCESS',
});

export const resetUser = (): ResetUser => ({
  type: 'RESET_USER',
});

// export const logoutUser = (): ThunkAction<
//   Promise<void>,
//   UserState,
//   undefined,
//   LogoutUserAction
// > => async (dispatch: ThunkDispatch<UserState, null, LogoutUserAction>): Promise<void> => {
//   return {} as Promise<void>;
// };

export const loadMyBadgesSuccess = (badges: Badge[]): LoadMyBadgesSuccess => ({
  type: 'LOAD_MY_BADGES_SUCCESS',
  badges: badges,
});

export const loadMyBadgesError = (msg: string): LoadMyBadgesError => ({
  type: 'LOAD_MY_BADGES_ERROR',
  msg,
});

export const loadMyBadges = (): ThunkAction<
  Promise<void>,
  UserState,
  null,
  LoadUserBadgesAction
> => async (dispatch: ThunkDispatch<UserState, null, LoadUserBadgesAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetMyBadgesRequest = {};

  return client
    .GetMyBadges(req)
    .then((resp: GetMyBadgesResponse) => {
      const [badges, err] = validateGetMyBadgesResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadMyBadgesSuccess(badges));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadMyBadgesError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const addToMyBadgesSuccess = (badge: Badge): AddToMyBadgesSuccess => ({
  type: 'ADD_TO_MY_BADGES_SUCCESS',
  badge: badge,
});

export const addToMyBadges = (
  badge: Badge,
): ThunkAction<Promise<void>, UserState, null, AddToMyBadgesSuccess> => async (
  dispatch: ThunkDispatch<UserState, null, AddToMyBadgesSuccess>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: AddEmployeeBadgeRequest = {
    badgeId: badge.id,
  };

  return client
    .AddEmployeeBadge(req)
    .then((resp: AddEmployeeBadgeResponse) => {
      const err = validateAddEmployeeBadgeResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(addToMyBadgesSuccess(badge));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};

export const loadMyInterestsSuccess = (interests: Interest[]): LoadMyInterestsSuccess => ({
  type: 'LOAD_MY_INTERESTS_SUCCESS',
  interests: interests,
});

export const loadMyInterestsError = (msg: string): LoadMyInterestsError => ({
  type: 'LOAD_MY_INTERESTS_ERROR',
  msg,
});

export const loadMyInterests = (): ThunkAction<
  Promise<void>,
  UserState,
  null,
  LoadUserInterestsAction
> => async (dispatch: ThunkDispatch<UserState, null, LoadUserInterestsAction>): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: GetMyInterestsRequest = {};

  return client
    .GetMyInterests(req)
    .then((resp: GetMyInterestsResponse) => {
      const [interests, err] = validateGetMyInterestsResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(loadMyInterestsSuccess(interests));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      dispatch(loadMyInterestsError(errResp.message));
      return Promise.reject(errResp);
    });
};

export const addToMyInterestsSuccess = (interest: Interest): AddToMyInterestsSuccess => ({
  type: 'ADD_TO_MY_INTERESTS_SUCCESS',
  interest: interest,
});

export const addToMyInterests = (
  interest: Interest,
): ThunkAction<Promise<void>, UserState, null, EditMyInterestAction> => async (
  dispatch: ThunkDispatch<UserState, null, EditMyInterestAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: AddEmployeeInterestRequest = {
    interestId: interest.id,
  };

  return client
    .AddEmployeeInterest(req)
    .then((resp: AddEmployeeInterestResponse) => {
      const err = validateAddEmployeeInterestResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(addToMyInterestsSuccess(interest));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};

export const removeFromMyInterestsSuccess = (interest: Interest): RemoveFromMyInterestsSuccess => ({
  type: 'REMOVE_FROM_MY_INTERESTS_SUCCESS',
  interest: interest,
});

export const removeFromMyInterests = (
  interest: Interest,
): ThunkAction<Promise<void>, UserState, null, EditMyInterestAction> => async (
  dispatch: ThunkDispatch<UserState, null, EditMyInterestAction>,
): Promise<void> => {
  const { ZALL_ENGINE_PROXY } = DynamicConfig.GetConfig();
  const client = new ZallWebClientImpl(new RpcImpl(ZALL_ENGINE_PROXY));
  const req: RemoveEmployeeInterestRequest = {
    interestId: interest.id,
  };

  return client
    .RemoveEmployeeInterest(req)
    .then((resp: RemoveEmployeeInterestResponse) => {
      const err = validateRemoveEmployeeInterestResp(resp);
      if (err.code === StatusType.STATUS_TYPE_SUCCESS) {
        dispatch(removeFromMyInterestsSuccess(interest));
        return Promise.resolve();
      } else {
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      const errResp = GetDisplayError(err);
      return Promise.reject(errResp);
    });
};
