import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading, Text, Flex, spaceMixin, Spacer } from '@zillow/constellation';
import { RootState, Employee, NamePronunciation } from '../../store';
import {
  selectWallOwnerFullName,
  selectWallOwnerTitle,
  selectWallOwnerEmail,
  selectWallOwnerNamePronunciation,
  selectWallOwnerWorkingLocation,
  selectWallOwnerHireDate,
  selectWallOwnerOfficePhone,
  selectWallOwnerPronoun,
  selectWallOwnerAssistant,
  selectWallOwnerAssistantToAlphabetical,
  selectWallOwnerLocalTime,
  updateNamePronunciation,
  updatePronoun,
  selectWallOwnerPrimaryContactNumber,
  updatePrimaryContactNumber,
  selectWallOwnerLinkedinProfileUrl,
  updateLinkedinProfileUrl,
} from '../../store/wall';
import NamePronunciationContainer from '../../containers/NamePronunciationContainer';
import { Pronouns } from '../../components/Pronouns';
import { PhoneNumberField } from '../../components/PhoneNumberField';
import { AnchorLink, ZallWallLink } from '../../components/Links';
import { DateUtils } from '../../utilities/dateutils';
import { AudioPlayerIcon } from '../../components/AudioPlayerIcon/AudioPlayerIcon';
import { URLInputField } from '../../components/URLInputField';
import { Pronoun, selectPronounMap } from '../../store/wallConfig';
import DynamicConfig from '../../config/DynamicConfig';

interface StateProps {
  fullName: string;
  title: string;
  email: string;
  workingLocation: string;
  startDate?: Date;
  officePhone?: string;
  pronouns?: Pronoun;
  pronounOptions: Map<string, string>;
  assistant?: Employee;
  assistantTo?: Employee[];
  localTime?: string;
  namePronunciation?: NamePronunciation;
  primaryContactNumber?: string;
  linkedinProfileUrl?: string;
  updatePronoun: (pronounId: string, pronounName: string) => Promise<void>;
  updateNamePronunciation: (
    audio: Uint8Array,
    description: string,
    deleteAudio: boolean,
  ) => Promise<void>;
  updatePrimaryContactNumber: (primaryContactNumber: string) => Promise<void>;
  updateLinkedinProfileUrl: (url: string) => Promise<void>;
}

interface OwnProps {
  /** if this is for current user */
  isCurrentUser: boolean;
  /** if employee is a contingent worker */
  isContingentWorker: boolean;
}

export type EmployeeInfoContainerProps = StateProps & OwnProps;

const Name = styled(Heading)`
  line-height: 54px;
  width: fit-content;
`;

const AudioIconWrapper = styled.div`
  margin-top: 10px;
  margin-left: ${spaceMixin('sm')};
`;

const EmployeeInfoContainerBase: React.FC<EmployeeInfoContainerProps> = ({
  fullName,
  title,
  email,
  workingLocation,
  startDate,
  officePhone,
  pronouns,
  pronounOptions,
  namePronunciation,
  isCurrentUser,
  assistant,
  assistantTo,
  localTime,
  primaryContactNumber,
  linkedinProfileUrl,
  isContingentWorker,
  updatePronoun,
  updateNamePronunciation,
  updatePrimaryContactNumber,
  updateLinkedinProfileUrl,
}: EmployeeInfoContainerProps) => {
  const { ZALL_ENGINE_PROXY, LINKEDIN_DOMAIN } = DynamicConfig.GetConfig();
  // add timestamp query to bypass browser cache
  const namePronunAudio = namePronunciation?.audioUrl
    ? `${ZALL_ENGINE_PROXY}${namePronunciation.audioUrl}?ts=${Date.now()}`
    : undefined;

  return (
    <>
      <Flex display="flex">
        <Name level="2" marginTop="xs" data-testid="employee-info-container-name">
          {fullName} {isContingentWorker && <Text>(Contingent Worker)</Text>}
        </Name>

        {namePronunAudio && (
          <AudioIconWrapper
            onClick={() =>
              ReactGA.event({
                category: 'Profile',
                action: 'Clicked Name Pronunciation Audio button',
              })
            }
          >
            <AudioPlayerIcon audioURL={namePronunAudio} />
          </AudioIconWrapper>
        )}
      </Flex>

      <Text>{title}</Text>

      <AnchorLink
        href={`mailto:${email}`}
        onClick={() =>
          ReactGA.event({ category: 'Profile', action: 'Clicked Employee Email link' })
        }
        marginY="xs"
      >
        {email}
      </AnchorLink>

      <PhoneNumberField
        label="Mobile #"
        addPhoneNumberCTA="Add Mobile Number"
        phoneNumber={primaryContactNumber}
        editable={isCurrentUser}
        onSave={updatePrimaryContactNumber}
      />

      {startDate && (
        <Text fontType="bodySmall">Started: {DateUtils.FormatDateToString(startDate)}</Text>
      )}

      <URLInputField
        label="LinkedIn profile"
        addUrlCTA="Add LinkedIn Profile"
        editable={isCurrentUser}
        urlValue={linkedinProfileUrl}
        placeholderText={`${LINKEDIN_DOMAIN}/in/mylinkedin/`}
        onSave={updateLinkedinProfileUrl}
        validateInput={(input: string) => input === '' || input.startsWith(LINKEDIN_DOMAIN)}
        gaCategory="Profile"
        gaAction="Edit LinkedIn Profile URL"
      />

      <Text fontType="bodySmall">Working location: {workingLocation}</Text>

      {localTime && <Text fontType="bodySmall">Local time: {localTime}</Text>}

      {/* only hide it when not current user and no pronouns defined or 'Do not display' is chosen */}
      {(isCurrentUser || (pronouns && pronouns.name !== 'Do not display')) && (
        <Pronouns
          preferredPronouns={pronouns}
          pronounOptions={pronounOptions}
          onSubmit={(pid: string) => {
            if (pid !== pronouns?.id && pronounOptions.get(pid))
              // eslint-disable-next-line
              updatePronoun(pid, pronounOptions.get(pid)!);
          }}
          editable={isCurrentUser}
        />
      )}
      <Text>
        {namePronunciation?.description && (
          <Text fontType="bodySmall" marginRight="xs">
            Pronounces name: <strong>{namePronunciation.description}</strong>
          </Text>
        )}
        {isCurrentUser && (
          <NamePronunciationContainer
            pronunciationAudio={namePronunAudio}
            pronunciationText={namePronunciation?.description}
            onSave={updateNamePronunciation}
          />
        )}
      </Text>

      <Spacer marginTop="xs" />

      {assistant && (
        <Text fontType="bodySmall">
          Assistant:&nbsp;
          <ZallWallLink
            to={`/${assistant.username}`}
            onClick={() =>
              ReactGA.event({ category: 'Profile', action: 'Clicked Assistant Name link' })
            }
          >
            {assistant.fullName}
          </ZallWallLink>
        </Text>
      )}

      {assistantTo && assistantTo.length > 0 && (
        <Text fontType="bodySmall">
          I support:&nbsp;
          {assistantTo.map((exec, i) => (
            <span key={i}>
              <ZallWallLink
                to={`/${exec.username}`}
                onClick={() =>
                  ReactGA.event({ category: 'Profile', action: "Clicked 'I support' Name link" })
                }
              >
                {exec.fullName}
              </ZallWallLink>
              {i < assistantTo.length - 1 && <span>, </span>}
            </span>
          ))}
        </Text>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  fullName: selectWallOwnerFullName(state),
  title: selectWallOwnerTitle(state),
  email: selectWallOwnerEmail(state),
  workingLocation: selectWallOwnerWorkingLocation(state),
  startDate: selectWallOwnerHireDate(state),
  officePhone: selectWallOwnerOfficePhone(state),
  pronouns: selectWallOwnerPronoun(state),
  pronounOptions: selectPronounMap(state),
  namePronunciation: selectWallOwnerNamePronunciation(state),
  assistant: selectWallOwnerAssistant(state),
  assistantTo: selectWallOwnerAssistantToAlphabetical(state),
  localTime: selectWallOwnerLocalTime(state),
  primaryContactNumber: selectWallOwnerPrimaryContactNumber(state),
  linkedinProfileUrl: selectWallOwnerLinkedinProfileUrl(state),
});

const mapDispatchToProps = {
  updatePronoun: updatePronoun,
  updateNamePronunciation: updateNamePronunciation,
  updatePrimaryContactNumber: updatePrimaryContactNumber,
  updateLinkedinProfileUrl: updateLinkedinProfileUrl,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

const EmployeeInfoContainer = connect<StateToPropsType, DispatchToPropsType, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeInfoContainerBase);

export { EmployeeInfoContainer as default, EmployeeInfoContainerBase };
