import React from 'react';
import ReactGA from 'react-ga4';
import { Input, Text, Flex, TextButton, Anchor } from '@zillow/constellation';
import styled from 'styled-components';
import { URLInputFieldLogic } from './URLInputField.hooks';

export interface URLInputFieldProps {
  /** label of the input */
  label: string;
  /** CTA to start adding url */
  addUrlCTA: string;
  /** editable by current user or not */
  editable: boolean;
  /** previously entered value */
  urlValue?: string;
  /** placeholder text */
  placeholderText?: string;
  /** save input handler */
  onSave: (url: string) => void;
  /** validation logic */
  validateInput?: (input: string) => boolean;
  /** GA props */
  gaCategory: string;
  gaAction: string;
}

const InlineInput = styled(Input)`
  min-width: 360px;
`;

const AddURLAnswerButton = styled(TextButton)`
  display: block;
  width: fit-content;
  text-transform: capitalize;
`;

export const URLInputField: React.FC<URLInputFieldProps> = (props: URLInputFieldProps) => {
  const { label, addUrlCTA, editable, placeholderText, gaCategory, gaAction } = props;

  const { isEditing, setIsEditing, inputValue, onInputChange, handleEditClick, isInputValid } =
    URLInputFieldLogic(props);

  if (!inputValue && !isEditing) {
    return editable ? (
      <AddURLAnswerButton
        fontType="bodySmallHeading"
        onClick={() => {
          ReactGA.event({ category: gaCategory, action: gaAction });
          setIsEditing(true);
        }}
        data-testid="render-cta-button-test"
      >
        {addUrlCTA}
      </AddURLAnswerButton>
    ) : null;
  }

  return (
    <Flex>
      <Text fontType="bodySmall">{label}: </Text>
      {isEditing ? (
        <InlineInput
          fluid={false}
          name="url input"
          type="url"
          placeholder={placeholderText || ''}
          value={inputValue}
          onChange={onInputChange}
          data-testid="render-url-input-test"
        />
      ) : (
        <Text fontType="bodySmall">
          <Anchor href={inputValue} target="_blank" data-testid="render-url-link-test">
            {inputValue}
          </Anchor>
        </Text>
      )}

      {editable && (
        <TextButton
          fontType="bodySmallHeading"
          disabled={!isInputValid}
          onClick={handleEditClick}
          marginLeft="xs"
          data-testid="render-edit-button-test"
        >
          {isEditing ? 'Save' : 'Edit'}
        </TextButton>
      )}
    </Flex>
  );
};
